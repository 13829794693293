@use "../mixins/mq";
@use "../_base/typography";

.nav--footer-meta-nav {
	.nav {
		&__wrap {
			&--parent {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2rem var(--grid-gap);

				@include mq.mq($from: large) {
					display: flex;
					justify-content: flex-end;
					gap: 3rem;
				}
			}
		}
		&__link--parent {
			
		}
	}
}
