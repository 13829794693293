@use "../mixins/mq";
@use "../atoms/heading";

.hero {
  &__section {
    display: flex;
    padding: 10rem 0 5rem;
    background-color: var(--color-grey-600);
    min-height: 100vh;
    align-items: center;
    @include mq.mq($from: large) {
      padding: 25rem 0;
    }

    h1,
    .h1 {
      hyphens: none;
    }
  }
}
