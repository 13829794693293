@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
	display: block;
	font-size: var(--nav-font-size, 1.4rem);
	--link-color: currentColor;
	--outline-offset: 0;

	.is-active {
		--link-color: var(--color-brand-1);
	}

	.green-steel-item {
		.is-active {
			--link-color: var(--color-secondary-1);
		}
	}

	&__wrap {
		list-style: none;	
		padding: 0;
		margin: 0;

	
			&--child {
		
				
				

				

			}


	}

	&__item {
		padding: 0;
		margin: 0;
	}

	&__link {
		//color: var(--nav-link-color, inherit);
		font-size: var(--nav-link-font-size, inherit);
		display: block;
		&--parent {
			font-size: var(--nav-link-parent-font-size, inherit);
		}
	}

	&__toggle {
		
	}

	&__text {

	}

/*
	&__icon {
		height: 3rem;
		width: auto;
	}

	&__item {
		position: relative;
		padding-left: 0;

		&:not(:first-child) {
			margin-top: 0;
		}

		.icon-chevron-down,
		.icon-chevron-down svg {
			@include mq.mq($from: large) {
				width: 20px;
				height: 12px;
			}
		}

		&--child {
			padding-right: 7.5rem;
			width: 100%;

			&:hover {
				@include background-svg.params(svg.$svg-arrow-right, transparent, right, center, no-repeat, 22px, 22px, colors.$color-primary-1);
			}
		}
	}

	&__link {
		text-decoration: none;
		color: var(--color-black);

		&.is-active {
			color: var(--color-primary-1);
		}

		&--child {
			font-size: 1.8rem;
			line-height: 3.8rem;
		}

	}

	&__toggle {
		position: absolute;
		top: 0.5rem;
		right: -1.5rem;
		width: 5rem;
		height: 5rem;
		transform-origin: center center;
		transition: transform var(--animation-duration) var(--animation-ease);
		color: inherit;

		.icon {
			color: var(--color-black);
		}


		.nav__item--parent-opened & {
			transform: rotate(180deg);
		}

		@include mq.mq($from: large) {
			display: none;
			visibility: hidden;
		}
	}

	&__wrap {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
		width: 100%;

		&--parent {
			.nav--main-nav &,
			.nav--meta-nav & {
				@include mq.mq($from: large) {
					justify-content: flex-end;
				}
			}
		}

		&--child {
			max-height: 0;
			transition: max-height 0.5s var(--animation-ease);
			overflow: hidden;
			width: auto;

			.nav__item--parent-opened > & {
				max-height: 150rem;
			}

			@include mq.mq($from: large) {
				position: absolute;
				left: 0;
				top: 100%;
				display: none;
				max-height: unset;
				flex-direction: column;
				padding: 2.6rem;
				opacity: 0;
				transform: translate3d(-2.6rem, -0.6rem, 1px);
				white-space: nowrap;
				background-color: var(--color-white);
				box-shadow: 0 2px 20px 0 #1A191920;
			}
		}

		&.nav__wrap--visible {
			display: flex;
			z-index: 9998;
			visibility: visible;
		}
	}
	*/
}