@use "../mixins/mq";

.list {

    &__link {
        height: 10rem;
        display: flex;
        align-items: flex-end;

        .action {
            height: 5.8rem;
        }
    }

    &__link--right {
        justify-content: flex-end;
    }

    &__link--left-mobile {
        @include mq.mq($until: medium) {
            justify-content: flex-start;
        }
    }
}
