@use "../atoms/heading";
@use "../mixins/mq";

.list-with-icon {


  &__content {
    h3,
    .h3 {
    }
  }

  &__icon {
    --icon-width: 5.7rem;
    --icon-height: 5.7rem;
  }

  &__list {
    --grid-gap: 10rem;

    display: grid;
    gap: var(--grid-gap);

    @include mq.mq($from: large) {
      --grid-gap: 10rem 2.5rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }


}