@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

.lang-chooser {
	--link-color: currentColor;
	position: static;
	color: var(--link-color, inherit);
	text-transform: uppercase;
	font-size: 1.6rem;
	-webkit-appearance: none; 
	-moz-appearance: none;
	appearance: none;
	@include background-svg.params(svg.$svg-chevron-down, transparent, right, center, no-repeat, 9px, 5px, colors.$color-black);
	padding-right: 1.6rem;
	cursor: pointer;
	transition: color var(--animation-duration) var(--animation-ease);
  	will-change: color;
	margin-right: 1.6rem;
	margin-top: 0.6rem;
	align-self: flex-start;


	@include mq.mq($from: large) {
		position: static;
		left: 2rem;
		top: 2.5rem;
		font-size: 1.8rem;
		margin: 0;
	}
	&:hover {
		--link-color: var(--color-primary-1);
		@include background-svg.params(svg.$svg-chevron-down, transparent, right, center, no-repeat, 9px, 5px, colors.$color-primary-1);
	}
	@include mq.mq($from: large) {
		@include background-svg.params(svg.$svg-chevron-down, transparent, right, center, no-repeat, 9px, 5px, colors.$color-black);

		.overlay-header & {
			@include background-svg.params(svg.$svg-chevron-down, transparent, right, center, no-repeat, 9px, 5px, colors.$color-white);
		}

		.header--detached &, .header--menu-open & {
			@include background-svg.params(svg.$svg-chevron-down, transparent, right, center, no-repeat, 9px, 5px, colors.$color-black);
		}
	}
}

.lang-chooser__item {
	position: relative;
	margin-top: 0;
	padding-left: 0;
	font-size: 1.4rem;

	&:not(:first-child) {
		margin-top: 0;
	}

	&:not(:first-child) {
		padding-left: 2rem;

		&:before {
			position: absolute;
			left: 0;
			top: 1px;
			display: block;
			width: 2rem;
			text-align: center;
			content: '|';
			line-height: normal;
		}
	}

	.nav__link {
		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}
	}
}
