@use "../_config/colors";
@use "../mixins/mq";

:root {
  --hyphens-style: auto;

  --container-widht: 160.8rem;

  --color-black: #{colors.$color-black};
  --color-grey-100: #{colors.$color-grey-100};
  --color-grey-200: #{colors.$color-grey-200};
  --color-grey-300: #{colors.$color-grey-300};
  --color-grey-400: #{colors.$color-grey-400};
  //--color-grey-500: #{colors.$color-grey-500};
  --color-grey-600: #{colors.$color-grey-600};
  --color-grey-border: #{colors.$color-grey-border};
  --color-white: #{colors.$color-white};
  --color-primary-1: #{colors.$color-primary-1};
  --color-primary-2: #{colors.$color-primary-2};
  --color-primary-3: #{colors.$color-primary-3};
  --color-primary-4: #{colors.$color-primary-4};
  --color-secondary-1: #{colors.$color-secondary-1};
  --color-secondary-2: #{colors.$color-secondary-2};
  --color-secondary-3: #{colors.$color-secondary-3};
  --color-error: #{colors.$color-error};
  --color-success: #{colors.$color-success};
  --color-warning: #{colors.$color-warning};
  --color-black-transparent: #{colors.$color-black-transparent};
  --color-disabled: #{colors.$color-disabled};
  --color-disabled-dark: #{colors.$color-disabled-dark};

  --color-primary-hd-1: #{colors.$color-primary-hd-1};
  --color-primary-hd-2: #{colors.$color-primary-hd-2};
  --color-secondary-hd-1: #{colors.$color-secondary-hd-1};
  --color-secondary-hd-2: #{colors.$color-secondary-hd-2};
  --color-secondary-hd-3: #{colors.$color-secondary-hd-3};

  --gradient-start: var(--color-primary-1);
  --gradient-end: var(--color-primary-2);

  --gradient-dark-start: var(--color-primary-2);
  --gradient-dark-end: var(--color-primary-4);

  --breadcrumb-color: var(--color-black);
  --link-big-font-size: 2rem;

  --spacing: 6rem;

  // theme colors
  --color-brand-1: var(--color-primary-1);
  --color-brand-2: var(--color-primary-2);
  --color-brand-3: var(--color-primary-3);

  //linear
  --linear-rotation: 45deg;

  //--color-black-linear: linear-gradient(var(--linear-rotation), var(--color-black), var(--color-grey-400));
  --color-black-linear: linear-gradient(var(--linear-rotation), rgba(0, 0, 0, 0.95) 0%, #555 100%);
  --color-grey-linear: linear-gradient(var(--linear-rotation), var(--color-grey-200), #F6F6F6);
  --color-primary-linear: linear-gradient(var(--linear-rotation), var(--color-primary-1), var(--color-primary-2));
  --color-primary-linear-dark: linear-gradient(var(--linear-rotation), var(--color-primary-2), var(--color-primary-4));
  --color-secondary-linear: linear-gradient(var(--linear-rotation), var(--color-secondary-3), var(--color-secondary-1), var(--color-secondary-2));
  --color-secondary-linear-dark: linear-gradient(var(--linear-rotation), var(--color-secondary-1), var(--color-secondary-2));

  --color-brand: var(--color-brand-2);
  --color-brand-linear: var(--color-primary-linear);
  --color-brand-linear-auto: var(--color-primary-linear);
  --color-brand-linear-dark: var(--color-primary-linear-dark);

  //action
  --action-color: var(--color-white);
  --action-bg-img: var(--color-primary-linear);
  --action-border-image: var(--color-primary-linear);
  --action-margin-right: 0;

  //link-big
  --link-big-bg-img: var(--color-primary-linear);

  //action active
  --action-active-color: currentColor;
  //--action-active-border-color: 
  --action-active-bg-img: none;
  --action-active-border-image: var(--color-primary-linear);

  //action disable
  --action-disable-color: var(--color-disabled);
  --action-disabled-border-color: var(--color-disabled);
  --action-disabled-bg-img: none;
  --action-disabled-border-image: none;

  --action-fab-outline-color: var(--color-black);
  --action-fab-outline-active-color: var(--color-white);
  --action-fab-outline-bg-color: var(--color-black);

  //body 
  --body-font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  --body-color: var(--color-black);
  --body-bg-color: var(--color-grey-100);
  --body-font-size: 1.8rem;
  --body-line-height: 1.6;
  --body-font-weight: 400;
  --body-letter-spacing: 0.075rem;

  --paragraph-spacing-top: 2rem;

  --font-size-label: 1.7rem;
  --font-size-label-small: 1.7rem;

  --outline-color: var(--body-color);


  // heading 
  --heading-font-weight: 300;
  --heading-margin-bottom: 0;
  //--heading-spacing: 4rem;
  --heading-spacing: 2.5rem; // reduced

  --font-size-h1: 4.8rem;
  --font-size-h2: 3.4rem;
  --font-size-h3: 2.6rem;
  --font-size-h4: 2rem;
  --font-size-h5: 1.6rem;

  --line-height-h1: 1.3;
  --line-height-h2: 1.3;
  --line-height-h3: 1.3;
  --line-height-h4: 1.3;
  --line-height-h5: 1.1;

  --letter-spacing-h1: normal;
  --letter-spacing-h2: normal;
  --letter-spacing-h3: 0.065rem;
  --letter-spacing-h4: 0.065rem;
  --letter-spacing-h5: 0.085rem;

  --spacing-h1: 3rem;
  --spacing-h2: 3rem;
  --spacing-h3: 3rem;
  --spacing-h4: 3rem;
  --spacing-h5: 3rem;
  
  
  --teaser-font-size: var(--font-size-h4);
  --teaser-line-height: var(--line-height-h4);
  --teaser-letter-spacing: var(--letter-spacing-h4);
  --teaser-font-weight: var(--heading-font-weight);


  --link-color: var(--color-brand);

  --font-size-button: 1.8rem;

  --button-primary-min-height: 6rem;
  --button-primary-min-width: 20rem;

  --spacing-text: 2rem;
  --spacing-list: 4rem;
  --spacing-media: 4rem;

  //layout
  --gutter: .8rem;
  --grid-gap: var(--gutter);
  --wrapper-gap: 1.6rem;

  --section-spacing: 8rem;
  --footer-padding-top: 4rem;
  --footer-padding-bottom: 3rem;

  --section-box-padding: 3.2rem var(--wrapper-gap);
  --section-box-margin: 0 calc( var(--wrapper-gap) * -1);
  --section-box-bg-color: var(--color-white);

  --header-height: 6.3rem;

  --hamburger-line-height: .1rem;
  --hamburger-padding: .85rem;
  --hamburger-megin-right: -.85rem;
  --hamburger-width: 3.7rem;
  --hamburger-spacing: .1rem;

  --formBorder: 1px solid #999;
  --formBorderRadius: 4px;
  --formInputHeight: 33px;

  --animation-duration: 0.25s;
  --animation-duration-slow: .8s;
  --animation-ease: cubic-bezier(.25, .46, .45, .75);



  //lists
  --list-margin-top: 4rem;
  --list-item-padding-left: 1.8rem;
  --list-margin-left: 2rem;
  --list-item-margin-top: 2rem;

  --unstyled-list-item-margin-top: 1.6rem;

  --fill-gradient: url(#gradient-brand);
  --fill-gradient-dark: url(#gradient-brand-dark);
  --fill-gradient-green-steel: url(#gradient-green-steel);
  --fill-gradient-green-steel-dark: url(#gradient-green-steel-dark);
  --fill-gradient-hydreams: url(#gradient-hydreams);
  --fill-gradient-hydreams-dark: url(#gradient-hydreams-dark);
  --fill-gradient-auto: var(--fill-gradient);


  --sticky-offset-top: calc( 50vh - 17rem );

  // scroll bar
  --scrollbar-color: var(--color-brand-2);
  --scrollbar-track: var(--color-grey-400);
  --scrollbar-thumb: var(--color-brand-2);

  @include mq.mq($from: medium) {
    --wrapper-gap: 4rem;
    --gutter: 2.4rem;
  }

  @include mq.mq($from: large) {

    //body
    --body-font-size: 2rem;

    --link-big-font-size: 3.4rem;

    // heading 
    //--font-size-h1: 9rem;
    --font-size-h1: 8rem; // reduced
    //--font-size-h2: 5.8rem;
    --font-size-h2: 5rem; // reduced
    //--font-size-h3: 3.4rem;
    --font-size-h3: 3.1rem; // reduced
    --font-size-h4: 2.4rem;
    --font-size-h5: 1.8rem;

    --line-height-h1: 1.2;
    //--line-height-h2: 1.3;
    //--line-height-h3: 1.3;
    //--line-height-h4: 1.3;
    //--line-height-h5: 1.1;

    //--letter-spacing-h1: normal;
    --letter-spacing-h2: .05rem;
    --letter-spacing-h3: 0.08rem;
    --letter-spacing-h4: 0.12rem;
    --letter-spacing-h5: normal;

    --spacing-h1: 4rem;
    --spacing-h2: 4rem;
    --spacing-h3: 4rem;
    --spacing-h4: 4rem;
    --spacing-h5: 4rem;

    --spacing-text: 3rem;
    --spacing-list: 6rem;
    --spacing-media: 6rem;
    --list-item-margin-top: 3rem;
    --list-item-padding-left: 2.8rem;

    
    --wrapper-gap: 7rem;
    //--section-spacing: 14rem;
    --section-spacing: 10rem; // reduced

    //--section-box-padding: 10rem 14rem;
    --section-box-padding: 10rem 10rem; // reduced
    --section-box-margin: 0;

    --footer-padding-top: 10rem;
    --footer-padding-bottom: 5rem;

    --header-height: 12.6rem;
    --unstyled-list-item-margin-top: 2.4rem;
    --sticky-offset-top: calc( 50vh - 12rem );
  }
}

$body-font-family: "Source Sans 3", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-duration: 0;
    --animation-ease: none;
  }
}


.green-steel {
  --gradient-start:  var(--color-secondary-3);
  --gradient-middle: var(--color-secondary-1);
  --gradient-end: var(--color-secondary-2);

  --gradient-dark-start:  var(--color-secondary-1);
  --gradient-dark-end: var(--color-secondary-2);

  --fill-gradient: var(--fill-gradient-green-steel);
  --fill-gradient-auto: var(--fill-gradient-green-steel);
  

  // theme colors
  --color-brand-1: var(--color-secondary-1);
  --color-brand-2: var(--color-secondary-2);
  --color-brand-3: var(--color-secondary-3);
  --color-brand: var(--color-brand-2);
 

  --color-brand-linear: var(--color-secondary-linear);
  --color-brand-linear-auto: var(--color-secondary-linear);
  --color-brand-linear-dark: var(--color-secondary-linear-dark);

  --action-bg-img: var(--color-secondary-linear);
  --action-border-image: var(--color-secondary-linear);
  --action-active-border-image: var(--color-secondary-linear);
  --action-active-color: currentColor;

  --link-color: var(--color-brand);

  --link-big-bg-img: var(--color-secondary-linear);

  --scrollbar-color: var(--color-brand-2);

}

.hydreams {

  --color-primary-1: var(--color-primary-hd-1);
  --color-primary-2: var(--color-primary-hd-2);

  --color-secondary-1: var(--color-primary-hd-1);
  --color-secondary-2: var(--color-primary-hd-2);
  --color-secondary-3: var(--color-primary-hd-2);


  --gradient-start:  var(--color-primary-hd-1);
  --gradient-end: var(--color-primary-hd-2);

  --gradient-dark-start:  var(--color-primary-hd-2);
  --gradient-dark-end: var(--color-primary-hd-1);

  --fill-gradient: var(--fill-gradient-hydreams);
  --fill-gradient-auto: var(--fill-gradient-hydreams);
  

  // theme colors
  --color-brand-1: var(--color-primary-1);
  --color-brand-2: var(--color-primary-2);
  --color-brand-3: var(--color-secondary-3);
  --color-brand: var(--color-brand-2);
 

  --color-brand-linear: var(--color-secondary-linear);
  --color-brand-linear-auto: var(--color-secondary-linear);
  --color-brand-linear-dark: var(--color-secondary-linear-dark);

  --color-secondary-linear: linear-gradient(var(--linear-rotation), var(--color-secondary-2), var(--color-secondary-1));
  --color-secondary-linear-dark: linear-gradient(var(--linear-rotation), var(--color-secondary-2), var(--color-secondary-1));

  --color-primary-linear: var(--color-brand-linear);

  --action-bg-img: var(--color-secondary-linear);
  --action-border-image: var(--color-secondary-linear);
  --action-active-border-image: var(--color-secondary-linear);
  --action-active-color: currentColor;

  --link-color: var(--color-brand);

  --link-big-bg-img: var(--color-secondary-linear);

  --scrollbar-color: var(--color-brand-2);

}

.dark,
.section--dark {

  --fact-decoration-bg-color: currentColor; 
  --action-fab-outline-color: var(--color-white);
  --action-fab-outline-active-color: var(--color-black);
  --action-fab-outline-bg-color: var(--color-white);

  --fill-gradient-auto: var(--fill-gradient-dark);
  --color-brand-linear-auto: var( --color-brand-linear-dark);

  --action-disable-color: var(--color-disabled-dark);
  --action-disabled-border-color: var(--color-disabled-dark);

  --section-box-padding: 0;
  --section-box-margin: 0;
  --section-box-bg-color: none;

  &.green-steel,
  .green-steel & {
    --fill-gradient-auto: var(--fill-gradient-green-steel-dark);
    --color-brand-linear-auto: var( --color-brand-linear-dark);
  }

  &.hydreams,
  .hydreams & {
    --fill-gradient-auto: var(--fill-gradient-hydreams-dark);
    --color-brand-linear-auto: var( --color-brand-linear-dark);
  }
}


