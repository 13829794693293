@use "../mixins/mq";

.breadcrumb__wrapper {
	--list-item-margin-top: 0;
	display: flex;
	visibility: visible;
}

.breadcrumb {
	display: flex;
	list-style: none;
	margin-top: 0;
	margin-left: 0;
}

.breadcrumb__wrapper {
	position: relative;
	z-index: 2;
	max-width: 100vw;
	white-space: nowrap;
	overflow-y: hidden;
	overflow-x: auto;
	height: 3rem;
	//overflow-y: hidden;
	padding: 0;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.breadcrumb--child {
	position: fixed;
	left: -22px;
	top: 100%;
	display: none;
	//display: flex;
	flex-direction: column;
	width: auto;
	max-width: 100%;
	/*min-width: calc(100% + 25px);*/
	padding: 1rem 0;
	background-color: var(--color-black);
	word-break: keep-all;
	white-space: nowrap;
}

.breadcrumb__item {
	//position: relative;
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	height: 30px;
	overflow: visible;
	padding-left: 0;
	flex-shrink: 0;

	letter-spacing: 0.1rem;

	&:first-child {
		margin-right: 1.5rem;
	}

	&:not(:first-child) {
		margin-top: 0;
	}

	.icon-chevron-right {
		margin: 0 1rem;
	}
}

.breadcrumb__trigger {
	height: 30px;
	margin-left: var(--spacingSmall);
	color: var(--breadcrumb-color);

	.breadcrumb--light & {
		color: var(--color-white);
	}

	.icon {
		transform-origin: center center;
	}
}

.breadcrumb__item-title {
	display: inline-flex;
	align-items: center;
	height: 30px;
	color: var(--breadcrumb-color);
	text-decoration: none;

	.breadcrumb--light & {
		color: var(--color-white);
	}
}

a.breadcrumb__item-title {
	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}

.breadcrumb__item--home {
	margin-left: 0;
}

.breadcrumb__link--home {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--breadcrumb-color);
}

.breadcrumb__item--last {
	padding-right: 3rem;
	color: var(--breadcrumb-color);
	cursor: default;
}

.breadcrumb__item--opened {
	.breadcrumb--child {
		display: flex;
	}

	.breadcrumb__trigger {
		.icon {
			transform: rotate(180deg);
		}
	}
}
