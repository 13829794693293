@use "../mixins/mq";

.card {
  --card-margin-top: 4rem;
  --category-font-size: 2rem;
  --title-font-size: 3.4rem;
  --intro-font-size: 2rem;
  --spacing-text: 1rem;
  --spacing-h2: 1rem;
  --spacing-figure: 4rem;
  --card-padding: 2.8rem 2.8rem 5.6rem;

  position: relative;
  display: block;
  margin-top: var(--card-margin-top);
  padding: var(--card-padding);
  color: inherit;
  background-color: var(--color-white);

  &:hover {
    text-decoration: none;
  }

  .filters__grid & {
    --card-margin-top: 0;
  }

  &--empty {
    display: none;
    visibility: hidden;
    padding: 0;
    height: 0;
    background: none;

    @include mq.mq($from: large) {
      display: block;
      visibility: visible;
      height: 30rem;
      background: none;
    }
  }

  @include mq.mq($from: large) {
    .filters__grid & {
      width: calc(50% - 2.5rem / 2);

      .teasers-news-filters__section & {
        width: 100%;
      }

    }
  }

  @include mq.mq($from: xxLarge) {
    --title-font-size: 5.8rem;
    --card-margin-top: 0;
    --spacing-text: 2rem;
    --intro-font-size: 2.4rem;
  }

  &__action {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;

    @include mq.mq($from: large) {
      gap: 4rem;
    }
  }

  &__category {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    padding: .6rem 1.2rem;
    background-color: var(--color-grey-200);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__clamp {
    display: var(--display);
    --spacing-text: 3rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    letter-spacing: 0.1rem;
    --display: block;
  }
  &__clamp--box {
    --display: -webkit-box;
  }

  &__cta {
    --spacing-text: 2rem;
    line-height: 1;

    @include mq.mq($from: xxLarge) {
      .card--small & {
        text-align: right;
        --spacing-text: 0.5rem;

        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__date {
    position: absolute;
    left: 0;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding: 2rem;
    font-size: 2.6rem;
    line-height: 3.3rem;
    letter-spacing: 0.08rem;
    text-align: center;
    color: var(--color-white);
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.55);

    &:hover {
      text-decoration: none;
    }

    @include mq.mq($from: large) {
      font-size: 3.4rem;
      line-height: 4.4rem;
    }
  }


  &__event-content {
    padding-top: 3rem;

    @include mq.mq($from: large) {
      padding-top: 0;
      padding-right: 30rem;
    }
  }

  &__event-info {
    --spacing-text: 0;
  }

  &__event-title {
    --spacing-h3: 0.5rem;
  }


  &__figure {
    margin-bottom: var(--spacing-figure);

    .card--small & {
      width: 10.3rem;

      @include mq.mq($from: medium) {
        width: 100%;
      }
    }
  }

  &__intro {
    font-size: var(--intro-font-size);

    &--news {
      --intro-font-size: 1.8rem;

      @include mq.mq($from: large) {
        --intro-font-size: 2rem;
      }
    }
  }

  &__logo {
    margin-top: 3rem;
    height: 3.4rem;

    @include mq.mq($from: large) {
      position: absolute;
      right: 4rem;
      top: 4rem;
      margin-top: 0;
    }

    &--square {
      height: 6.8rem;
    }
  }

  &__text-expand {
    margin-top: 3rem;
  }

  &__sup-title {
    font-size: var(--category-font-size);
  }

  &__sub-title {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 1.2;

    @include mq.mq($from: large) {
      font-size: 1.6rem;
    }
  }

  &__svg {
    height: 3.4rem;
    width: auto;

    &--square {
      height: 6.8rem;
    }
  }

  &__title {
    font-size: var(--title-font-size);
    line-height: 1.3;

    &--news {
      --title-font-size: 2.6rem;

      @include mq.mq($from: large) {
        --title-font-size: 3.4rem;
      }
    }
  }

  &--big {
    grid-area: teaserMain;
    align-self: start;

    @include mq.mq($from: large) {
      --card-padding: 7rem
    }
  }

  &--small {
    --card-margin-top:  1.6rem;
    --category-font-size: 1.4rem;
    --spacing-text: 0.5rem;
    --spacing-figure: 2rem;
    --title-font-size: 2rem;

    @include mq.mq($from: medium) {
      --category-font-size: 2rem;
      --card-padding: 2.5rem;
      --title-font-size: 2.6rem;
      --spacing-figure: 0;

      &.card--with-image {
        display: grid;
        grid-template-columns: 17.5rem 1fr;
        gap: 3rem;
      }
    }

    @include mq.mq($from: xxLarge) {
      --title-font-size: 3.4rem;

      &.card--with-image {
        gap: 5.5rem;
      }

      &:first-child {
        --card-margin-top: 0;
      }
    }
  }

  &--event {
    display: grid;
    margin-top: 1.6rem;

    @include mq.mq($from: large) {
      grid-template-columns: 24.4rem 1fr;
      gap: 5rem;
      padding: 4rem;
      margin-top: 2.4rem;
    }
  }

  &__image {
    position: relative;
    aspect-ratio: 68/45;
    background-color: var(--color-primary-1);

    @include mq.mq($from: large) {
      aspect-ratio: 1/1;
    }
  }
}