@use "../atoms/heading";
@use "../mixins/mq";

.text-image {
	&__item {
		--grid-gap: 5rem;
		display: grid;
		gap: var(--grid-gap);
		align-items: center;
		--spacing-h2: 0;

		* {
			direction: ltr;
			text-align: left;
		}

		&:not(:first-child) {
			margin-top: 6rem;

			@include mq.mq($from: large) {
				margin-top: 10rem;
			}
		}

		@include mq.mq($from: large) {
			--grid-gap: 2.5rem;
			grid-template-columns: 52rem 1fr;

			&--reverse {
				direction: rtl;
			}


		}
	}


	&__image {
		padding-right: 3rem;
		max-width: 52rem;

		@include mq.mq($from: large) {
			padding-right: 0;
		}
	}

	&__text {
		padding: 0 3rem;
		max-width: 70rem;

		@include mq.mq($from: large) {
			padding: 0 13.5rem;
			max-width: unset;
		}
	}
}