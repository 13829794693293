@use "../mixins/mq";

.fact {

    &--with-text {
        display: grid;
        max-width: 106rem;
        margin: 0 auto;
        gap: 2.4rem;

        @include mq.mq($from: large) {
            grid-template-columns: 1fr 1fr;
            align-items: center;
            --spacing-text: 0;

        }
    }

    &__label {
        margin: var(--fact-label-margin, 0 0 2rem);

        .fact--with-text & {
            margin-top: 3rem;
            margin-bottom: 0;
            font-size: 2.6rem;
        }
    }

    &__text {
        font-size: 2rem;
    }


    &__value {
        display: inline-block;
        margin: 0;

        .fact--with-text & {
            padding-bottom: 2rem;
            font-size: 8rem;
        }
    }


    &:after {
        content: "";
        display: block;
        min-width: var(--fact-decoration-width,10rem);
        width: 2.75em;
        height: 0.1rem;
        background-color: var(--fact-decoration-bg-color, #ACB2BD);
    }


    @include mq.mq($from: large ) {
        --fact-decoration-width: 23rem;
        --fact-label-margin: 1rem 0 3rem;

        .fact--with-text & {
            --fact-decoration-width: 11rem;
        }
    }

    &__link {
        --action-fab-outline-color: var(--color-white);
        --action-fab-outline-active-color: var(--color-black);
        --action-fab-outline-bg-color: var(--color-white);
        position: relative;
        max-width: 136rem;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        padding-top: 6.4rem;
        
        @include mq.mq($from: medium ) {
            grid-template-columns: 1fr 1fr;
            padding-top: 0;
        }

        &--text {
            font-size: 1.4rem;
            @include mq.mq($from: large ) {
                //font-size: 2.4rem;
                font-size: 2rem; // reduced
            }
        }

        &--title {
            font-size: 2rem;
            @include mq.mq($from: large ) {
                font-size: 3.4rem;
            }
        }

        .link-big {
            position: absolute;
            grid-column: 1;
            transform: translateY(5rem);
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            width: 100%;
            
            @include mq.mq($from: small ) {
                transform: translateY(9rem);
            }
            @include mq.mq($from: medium ) {
                grid-column: 2;
                transform: translateY(2rem);
            }
           
        }
    }
}

.facts__section:not(.section--bg) {
    margin-bottom: 20rem;

    @include mq.mq($from: large) {
        margin-bottom: 30rem;
    }
}