@use "../mixins/mq";

.anchors {
  &__item {
    --list-item-padding-left: 0;
    --list-item-margin-top: 0;
    border-bottom: 1px solid var(--color-primary-1);

    &:first-child {
      border-top: 1px solid var(--color-primary-1);
    }


  }

  &__list {
    --list-margin-left: 0;
    --spacing-list: 0;
    list-style: none;
    margin-top: 2.5rem;

    @include mq.mq($from: medium) {
      max-width: 27rem;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 300;

    @include mq.mq($from: large) {
      font-size: 2.4rem;
    }
  }
}
