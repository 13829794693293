@use "../mixins/mq";

table {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin-bottom: var(--globalMargin);
  tr {
    border-bottom: 1px solid var(--lightGray);
  }
  td, th {
    text-align: var(--table-cell-text-align, left);
    padding: calc(#{var(--globalPadding)} / 2);
  }
  thead {
    border-bottom: 2px solid var(--primaryColor);
    th {
      font-weight: bold;
    }
  }
}

.table {
  --subtitle-font-size: 1.4rem;
  --spacing-media-caption: 2rem;
  --table-cell-font-size: 1.6rem;

  font-variant-numeric: tabular-nums;
  border-collapse: collapse;
  border: 0;
  min-width: 100%;
  margin-bottom: 0;
  caption-side: bottom;
  table-layout: auto;
  border-radius: 0;
  position: relative;


  &--highlighted {
    --table-cell-bg-color: transparent;

    tr:hover {
      --table-cell-bg-color: var(--color-tertiary-800);
    }
  }

  &__th {
    &--highlighted {
      position: relative;
      padding-top: 1.4rem !important;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: var(--color-primary-700);
        bottom: auto;
        height: calc( var(--scroll-shadow-height) + 1.4rem);
        z-index: -1;
        border-radius: 2rem;
      }
    }
  }


  th,
  td {
    border-width: var(--table-cell-border-width, .1rem 0 0 0);
    border-color: var(--table-cell-border-color, var(--color-accent-03));
    border-style: var(--table-cell-border-style, solid);
    vertical-align: var(--table-cell-vertical-align, top);
    color: var(--table-cell-color, inherit);
    padding-block: var(--table-cell-padding-block, 1.4rem);
    padding-inline: var(--table-cell-padding-inline, 2rem);
    background-color: var(--table-cell-bg-color, var(--color-grey-100-transparent));
    font-size: var(--table-cell-font-size, inherit);
    width: var(--table-cell-width, 30rem);
    text-size-adjust: none;
    border-top: none;
    height: 5.5rem;
    -webkit-text-size-adjust: none;

    //remove space start end

    &:last-child {
      //padding-right: 0;
    }

    @include mq.mq($from: large) {
      --table-cell-padding-inline: 5rem;
    }
  }

  tr,
  th {
    --table-cell-border-width: .1rem 0 .1rem 0;
  }


  thead th,
  &__tr--footer td,
  &__tr--footer th,
  tfoot th {
    --table-cell-vertical-align: bottom;
    --table-cell-border-width: 0 0 .2rem;
    --table-cell-border-color: var(--color-black);
    --table-cell-border-style: solid;
    --table-cell-font-size: 1.8rem;
    --table-cell-padding-block: 0;
    --table-cell-bg-color: transparent;
    letter-spacing: 0.36px;


    &:first-child {
      padding-left: 1rem;
      text-align: left;

      @include mq.mq($from: large) {
        padding-left: 2rem;
        min-width: 34rem;
      }
    }

    &:nth-child(2) {
      --table-cell-padding-inline: 0;

      min-width: 1rem;
      width: 100%;
    }
  }

  tbody th {
    --table-cell-bg-color: var(--color-white);

    font-weight: inherit;
    
    &:first-child {
      hyphens: manual;
      padding-left: 1rem;

      @include mq.mq($from: large) {
        padding-left: 2rem;
      }
    }

    .section--dark & {
      --table-cell-bg-color: rgba(255, 255, 255, 0.1)
    }
  }

  tbody td {
    --table-cell-text-align: left;

    vertical-align: middle;

    &:nth-child(2) {
      --table-cell-padding-inline: 0;

      max-width: 1rem;
    }

    @include mq.mq($from: large) {
      --table-cell-text-align: center;
    }

    &.text-align-left {
      --table-cell-text-align: left;
    }
  }



  /*
  thead th {

  }
  */

  &__tr--footer {
    font-weight: bold;
  }

  &__tr--footer td,
  &__tr--footer th,
  tfoot th {
    --table-cell-vertical-align: top;
    --table-cell-border-width: .2rem 0;
    --table-cell-padding-block: 1.1rem;

  }

  th,
  td {}


  &__sub-row {
    --table-cell-border-color: var(--color-grey-300);
    --table-cell-color: var(--color-grey-600);
    --table-cell-padding-block: .9rem;

    .section--dark & {
      --table-cell-border-color: var(--color-grey-600);
      --table-cell-color: var(--color-grey-200);
    }
  }

  &__legend {
    --spacing-text-top: 2rem;
  }

}


.caption,
.table caption {
  margin-top: 2.5rem;
  font-size: var(--subtitle-small-font-size);
  line-height: var(--subtitle-small-line-height);
  letter-spacing: var(--subtitle-small-letter-spacing);
  font-weight: 500;
  color: currentColor;

  p {
    font-size: var(--subtitle-small-font-size);

  }
}

.table-responsive {
  min-height: 0.01%;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: var(--scroll-behavior);
  //-webkit-overflow-scrolling: touch;
  word-break: normal;
  //white-space: nowrap;
  position: relative;
  padding-bottom: 1.5rem;
  width: auto;

  &:last-child {
    margin-bottom: 0;

  }

  thead th {
    --table-cell-text-align: center;

    white-space: nowrap;
    height: 6.4rem;
    background-color: var(--color-grey-600);
    vertical-align: middle;
    color: var(--color-white);
    font-weight: 600;
    border-bottom: none;

    &.text-align-left {
      --table-cell-text-align: left;
    }
  }

  td,
  th {
    width: auto;
  }
}

.text-full-width {
  --spacing-text: 1rem;

  @include mq.mq($from: large) {
    --spacing-text: 1.1rem;
  }
}

.ul__table-description {
  --spacing-list: 3rem;
  --list-item-margin-top: 1rem;
  --list-item-padding-left: 0;
  --list-margin-left: 1.6rem;

  font-size: 1.6rem;

  @include mq.mq($from:large) {
    width: 66.7%;
  }
}

.text__body {
  .media {
    margin-inline: -2.8rem -1.9rem;

    @include mq.mq($from: large) {
      margin-inline: 0;
    }
  }
}