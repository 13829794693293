hr {
  background-image: var(--color-brand-linear);
  display: block;
  border: none;
  clear: both;
  height: .1rem;
  border: none;
  margin: 0;
  margin-top: var(--spacing-text);
}
