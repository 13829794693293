@use "../mixins/mq";


.nav--meta-nav {
	--link-color-active: var(--color-primary-1);
	--link-text-decoration-active: none;
	.nav {
		&__link {
			padding: 0.75rem 0;
		}

		&__wrap {
			padding: 0 var(--wrapper-gap);
			@include mq.mq($from: large ) {
				--nav-link-parent-font-size: 1.6rem;
				display: flex;
				padding: 0;
				justify-content: flex-end;
				gap: 0 4.2rem;
				padding-right: 4.2rem;
			  }
		}
	}

	//large
	@include mq.mq($from: large) {
		letter-spacing: normal;
		.nav {
			&__link {
				padding: 0;
			}
		}
	}
  
}

.header__nav-meta {
	display: flex;
	justify-content: space-between;
}
