@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.is-loading {
  opacity: 0;
}

.is-loaded {
  opacity: 1;
  transform: none;
}
.fade-in,
.is-ready {
  opacity: 1;
  animation: fadeIn .4s linear;
}

.transparent {
  opacity: 0;
}

.spin {
  opacity: 1;
  transform: rotateY(360deg);
  transition: transform 1.5s 500ms cubic-bezier(0.06, 0.64, 0.26, 1.06), opacity 1.5s 700ms;
}
