@use "../atoms/heading";

.fullscreen {
	&__section {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30rem 0;
		min-height: 80vh;


		h2,
		.h2 {
			@extend .heading;
			@extend .heading--center;
		}
	}
}
