@use "../mixins/mq";
@use "../atoms/heading";

.intro_with_background {

  &__link {
    margin-top: -2.8rem;
    z-index: 1;
    @include mq.mq($from: large) {
      margin-bottom: unset;
    }

   
      transform: translateY(2.8rem);

      @include mq.mq($from: large) {
        transform: translateY(5rem);
      }
   
  }

  &__row {
    align-items: end;

    @include mq.mq($from: large) {
      min-height: 80rem;
    }
  }

  &__section {
    display: flex;
    padding: var(--section-spacing) 0 0;
    align-items: center;

    background-color: var(--color-grey-300);

    /*
    margin-bottom: 2.8rem;
    @include mq.mq($from: large) {
      margin-bottom: 5rem;
    }
    */

    h1,
    .h1 {
      hyphens: none;
    }
  }

  &__text {
    padding-bottom: 4rem;

    @include mq.mq($from: large) {
      padding-bottom: 10rem;
      min-height: 45rem;
    }
  }
}


