.tile {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: var(--color-black);
	text-decoration: none;
	cursor: pointer;
	color: var(--color-white);
	border: none;
	border-radius: 0;
	padding: 5rem 5rem 5rem 3rem;
	min-height: 25rem;
	z-index: 1;
	min-height: var(--tile-min-height, 25rem);
	gap: 4rem;

	&:hover,
	&:focus {
		--img-transform: scale(1.1);
		--tile-outline-box-shadow: inset 0 0 0 var(--color-white), inset 1px 1px 0 var(--color-white), inset -1px -1px 0 var(--color-white)
	}

	&--tall {
		--tile-min-height: 50rem;
		
	}

	&--outline {
		background-color: transparent;
		border: solid .1rem var(--color-white);
		box-shadow: var(--tile-outline-box-shadow, inset 0 0 0 var(--color-white));
		transition: box-shadow var(--animation-duration) var(--animation-ease);
		will-change: box-shadow;
	}

	&__title {
		--spacing-top: .5rem;
	}

	&__tag {
		font-weight: 600;
	}

	&__footer {
		margin-top: auto;
	}

	&__cover {
		position: absolute;
		inset: 0;
		opacity: .3;
		z-index: -1;
	}

}