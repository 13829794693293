@use "../mixins/mq";

.applications {
    display: flex;
	flex-direction: column;
    gap: 6rem var(--grid-gap);
  

	&__tracks {
		--track-row-gap: 2rem;
		display: grid;
		gap: var(--track-row-gap);
	}
	&__rail {
		display: flex;
		flex-direction: column;
		gap: var(--track-row-gap) 0;
	}

	&__links{
		width: 100%;
	}


	@include mq.mq($from: medium ) {

		flex-direction: row;
		justify-content: space-between;
		align-items: center;



		&--reverse {
			flex-direction: row-reverse;
		}

		&--sticky {
			--track-text-position: sticky;
			align-items: unset;

		}

		&--products {
			flex-wrap: wrap;

			.row {
				width: 100%;
			}
		}
		
		&__description {
			width: 41.666666667%;
		}

		&__image {
			order: -1;
		}

		&__text {
			position: var(--track-text-position,static);
			top: var(--sticky-offset-top);
		}

		
		&__tracks {
			--track-row-gap: 2.4rem;
			grid-template-columns: repeat(2, 1fr);
			align-items: center;
			max-width: 79.2rem;
		
		}

		&__rail {
			transform: translateY( var(--applications-rail-transform,0%) );
			transition: transform var(--animation-duration) var(--animation-ease);
			will-change: opacity;

			--applications-rail-transform: var(--applications-rail-offset-left);

			&:last-child {
				--applications-rail-transform: var(--applications-rail-offset-right);
			}
		}
	}
	

}