@use "../_config/colors";
@use "../mixins/mq";

$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {
  #{$size},
  .#{$size} {
    --spacing-top: var(--spacing-#{$size});
    //--spacing-bottom: var(--spacing-#{$size});
    display: block;
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    //font-weight: var(--font-weight-#{$size});
    font-weight: var(--heading-font-weight);
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top);
    //margin-bottom: var(--spacing-bottom);

    .no-margin & {
      --spacing-top: 0;
      //--spacing-bottom: 0;
    }

    &:first-child {
      --spacing-top: 0;
    }
/*
    &:last-child {
      --spacing-bottom: 0;
    }
    */
  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
  }
}

strong,
b {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-300 {
  font-weight: 300;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.teaser {
  font-size: var(--teaser-font-size);
  line-height: var(--teaser-line-height);
  letter-spacing: var(--teaser-letter-spacing);
  font-weight: var(--teaser-font-weight);

}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}
/*
blockquote {
  quotes: "„" "”" "„" "’";
  &:before {
    content: open-quote;
  }
}
*/
figcaption {

}


blockquote,
p {
  margin-top: var(--spacing-text);
  &:first-child {
    --spacing-text: 0;
  }
}

p {
  --action-margin-right: 1rem;
}

::marker {
  color: var(--marker-color,currentColor);
  font-weight: 600;
  content: var(--marker-content, unsert);
}


ul, ol {
  margin-top: var(--spacing-list);
  margin-left: var(--list-margin-left);
  &:first-child {
    --spacing-list: 0;
  }

  ul, ol {
    --spacing-list: .2rem;
  }

}

.unstyled-list {
  list-style: none;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-margin-top: var(--unstyled-list-item-margin-top);
}


.reset-list {
  list-style: none;
  --spacing-list: initial;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-margin-top: initial;
}


ol {
  //list-style-type: decimal-leading-zero;
  --marker-content: counter(list-counter, decimal-leading-zero);
  counter-reset: list-counter;
}


li {
  padding-left: var(--list-item-padding-left);
  margin-top: var(--list-item-margin-top);
  counter-increment: list-counter;

  .unstyled-list & {
    &:first-child {
      --list-item-margin-top: 0;
    }
  }
  #location-liste & {
    &:first-child {
      --list-item-margin-top: var(--unstyled-list-item-margin-top);
    }
  }
  .download-list & {
    &:first-child {
      --list-item-margin-top: var(--unstyled-list-item-margin-top);
    }
  }
}



.link {

  &--icon {
    display: inline-flex;
    align-items: center;
    gap: 0 1.2rem;
    --icon-height: 1em;
    --icon-width: 1em;

    --link-color: currentColor;
    --link-color-active: var(--color-brand);
    --link-text-decoration-active: none;
  }
}