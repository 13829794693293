.text-gradient {
    --linear-rotation: 90deg;
    display: inline;
    background-image: var(--color-brand-linear);
    color: transparent;
    -webkit-background-clip: text;
	background-clip: text;

    &--dark {
        background-image: var(--color-brand-linear-dark);
    }

    //for auto switch to dark gradiant in dark section
    &--auto {
        background-image: var(--color-brand-linear-auto);
    }
}