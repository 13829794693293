.gradient {
  &__start {
    stop-color: var(--gradient-start);
    &--dark {
      stop-color: var(--gradient-dark-start);
    }
  }

  &__middle {
    stop-color: var(--gradient-middle);
    &--dark {
      stop-color: var(--gradient-dark-middle);
    }
  }

  &__end {
    stop-color: var(--gradient-end);
    &--dark {
      stop-color: var(--gradient-dark-end);
    }
  }
}