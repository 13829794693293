@use "../mixins/mq";

.component {

}


.section + .section--bg {
	margin-top: var(--section-spacing);
}

.section--bg + .section--bg {
	margin-top: 0;
}

.section:not(.section--bg).section--last {
	margin-bottom: var(--section-spacing);
}


.c-last {
	margin-bottom: 6rem;

	@include mq.mq($from: large) {
		margin-bottom: 12rem;
	}
}

.section--spacing-bottom {

	&.facts__section:not(.section--bg) {
		margin-bottom: 20rem;
		@include mq.mq($from: large) {
			margin-bottom: 30rem;
		}
	}
}