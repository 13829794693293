@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";

.field__row {

	* {
		font-family: inherit
	}

}

.field-group {
	position: relative;

	&--search {
		color: var(--color-grey-300);
	}

	.row & {
		& + .field-group {

			@include mq.mq($from: large) {
				margin-left: 6rem;
			}
		}
	}

	&--bordered {
		&:before {
			position: absolute;
			left: -7px;
			right: -7px;
			bottom: 0;
			height: 100%;
			display: block;
			content: '';
			background-color: #F21C2810;
			z-index: 0;
		}
	}


	&--file {
		margin-bottom: 4rem;
		margin-top: 4rem;
	}

	&--html {
		margin-bottom: var(--spacingWide);
		color: var(--color-black);

		@include mq.mq($from: large) {
			margin-bottom: 10rem;
		}
	}

	&--block {
		margin-top: 3rem;
	}
}


.field {
	position: relative;
	display: block;
	min-height: 4rem;
	max-width: 100%;
	border: 0;
	//width: 100%;
	font-size: 1.6rem;
	background: none;

	&::placeholder {
		font-size: 1.6rem;
		color: var(--color-black);
	}

	&--search,
	&--text {
		padding: 2rem;
		height: 4.3rem;
		border: 1px solid var(--color-black);
	}

	&--select {
		font-size: var(--font-size-label-small);
		border-bottom: 1px solid var(--color-black);

		&-bigger {
			font-size: var(--font-size-label);
		}
	}


	&--search {
		min-width: 30rem;
		padding-left: 4.3rem;
		padding-right: 2rem;
		color: var(--color-white);
		background-color: var(--color-black);

		&::placeholder {
			color: var(--color-white);
		}
	}

	&--dates {
      	min-width: 25rem;
		padding-left: 4.3rem;
	}


	&--file {
		width: auto;
		min-height: unset;
	}
}

.label {
	position: relative;
	display: block;

	.field-group--block & {
		position: absolute;
		left: 0;
		top: 0.2rem;
		width: 100%;
		transition: all var(--animation-duration) var(--animation-ease);
		cursor: pointer;
	}

	.field-group--block-animated & {
		font-size: var(--font-size-label);
		line-height: 2rem;
		color: var(--color-grey-200);
		transform: translateY(-2.7rem);
	}

	.field-group--textarea.field-group--block-animated & {
		transform: translateY(-3.5rem);
	}

	&.required {
		&:after {
			content: '*';
		}
	}
}


input[type=checkbox],
.field__input--check,
.field__input--radio {
	@extend .visually-hidden;
}


.field__sub-label {
	position: relative;
	display: block;
	padding-top: 0.2rem;
	margin-top: 2rem;
	padding-left: 6rem;
	min-height: 4rem;
	cursor: pointer;

	&:first-of-type {
		margin-top: 1rem;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 4rem;
		height: 4rem;
		content: '';
		border: 1px solid var(--color-black);
	}

	.field__input--radio + & {
		&:before {
			border-radius: 50%;
		}
	}

	input[type=checkbox]:checked + &,
	.field__input--check:checked + & {
		&:before {
			@include background-svg.params(svg.$svg-check, transparent, center, center, no-repeat, 20px, 12px, colors.$color-primary-1);
		}
	}

	.field__input--radio:checked + & {
		&:after {
			position: absolute;
			left: .4rem;
			top: .4rem;
			display: block;
			content: '';
			width: 3.4rem;
			height: 3.4rem;
			background-color: var(--color-primary-1);
			border-radius: 50%;
		}
	}

	&--inline {
		display: inline-flex;
		margin-right: 4rem;
	}
}

.field__input--textarea {
	transition: height var(--animation-duration) var(--animation-ease);
}

.field__errors {
	margin-top: 1rem;
	font-size: 1.6rem;
	line-height: 3.2rem;
	letter-spacing: 0.1rem;
	@extend .font-weight-600;
	color: var(--color-error);
	list-style: none;
	margin-left: 0;

	li {
		padding-left: 0;
	}

	:is(.field-group--checkbox_group) + & {
		position: relative;
		top: auto;
		margin-top: 1rem;
	}
}

.field-group--submit {
	.action + .action {
		margin-left: 2rem;
	}
}



.field--bordered {
	border-bottom: 1px solid var(--color-black);

	.field-group--has-errors & {
		border-bottom: 2px solid var(--color-error);
	}
}

[disabled]+.field__sub-label {
    opacity: .5;
    pointer-events: none
}