@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";

.footer {
  font-size: var(--font-size-h5);
  line-height: 1.2;
  --nav-link-font-size: var(--font-size-h5);
  --nav-link-parent-font-size: var(--font-size-h5);

  padding-top: var(--footer-padding-top);
  padding-bottom: var(--footer-padding-bottom);

  --spacing-h4: 4rem;
  --spacing-text: 0;

  --section-spacing-top: 0;

  &__back-to-top {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
 
  &__grid {
    display: grid;
    gap: 6rem var(--grid-gap);
  }

  &__copyright {
    white-space: nowrap;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: var(--footer-action-align, flex-start);
    gap: 4rem 0;
  }

  &__separator {
    background-image: var(--color-primary-linear);

    .green-steel & {
      background-image: var(--color-secondary-linear);
    }
  }

  @include mq.mq($from: medium) {
    
    &__actions {
      order: 1;
      gap: 6rem 0;
    }

    &__grid {

      &--top {
        grid-template-columns: 10fr 2fr;
      }

      &--society {
        --footer-action-align: flex-end;

        grid-template-columns: 9fr 3fr;
       
      }

   
    
    }
    
  }

  @include mq.mq($from: large) {
    &__grid {
      &--bottom {
        display: flex;
        align-items: center;
        gap: 0 var(--grid-gap);
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }



}
