.ssg-logo-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.ssg-logo-circle--top {
  background: conic-gradient( #ef7d00 2deg, #ffdd00 128deg, #e63323 224deg, #E63624 224.9972deg, #ef7d00 358deg);
}
.ssg-logo-circle--bottom {
  background: conic-gradient(#e63323 44.9972deg, #cb0f19 162.8deg, #9e1515 281.6deg, #e63323 404deg);
}

.logo-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.logo-circle--top {
        background: conic-gradient( #ef7d00 2deg, #ffdd00 128deg, #e63323 224deg, #E63624 224.9972deg, #ef7d00 358deg);
}
.logo-circle--bottom {
  background: conic-gradient(#e63323 44.9972deg, #cb0f19 162.8deg, #9e1515 281.6deg, #e63323 404deg);
}
.logo-overflow {
  overflow: visible;
}