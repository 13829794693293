.events {
  &__category {
  }

  &-overview__no-result {
    margin-top: 4rem;
  }

  &-overview__load-more {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
}