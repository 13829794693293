@use "../mixins/mq";

.icon {
	position: relative;
	display: var(--icon-display, block);
	font-style: normal;
	color: var(--icon-color, currentColor);
	width: var(--icon-width, auto);
	height: var(--icon-height, auto);
	
	&--black {
		--icon-color: var(--color-black);
	}
	&--white {
		--icon-color: var(--color-white);
	}
}

$icons: (
	('close', 12, 12),
	('home', 20, 18),

	('play', 20, 20, 20, 20, 36, 40),
);


@each $name,
	$widthSmall,
	$heightSmall,
	$widthMedium,
	$heightMedium,
	$widthLarge,
	$heightLarge in $icons {
	.icon-#{$name} {
		--icon-width: #{calc($widthSmall / 10)}rem;
		--icon-height: #{calc($heightSmall / 10)}rem;

		@if $widthMedium and $widthMedium != 0 {
			@include mq.mq($from: medium) {
				--icon-width: #{calc($widthMedium / 10)}rem;
				--icon-height: #{calc($heightMedium  / 10)}rem;
			}
		}
		@if $widthLarge and $widthLarge !=  0  {
			@include mq.mq($from: large) {
				--icon-width: #{calc($widthLarge / 10)}rem;
				--icon-height: #{calc($heightLarge / 10)}rem;
			}
		}
	}
}

.icon-gradient {
	path {
		fill: var(--fill-gradient);
	}
}
.icon-gradient-auto {
	path {
		fill: var(--fill-gradient-auto);
	}
}