@use "../mixins/mq";

.locations {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--grid-gap);
  }

  &__list {
    display: grid;
    gap: 6rem;

    @include mq.mq($from: medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq.mq($from: large) {
      grid-template-columns: repeat(2, 1fr);
      gap: 10rem var(--grid-gap);
    }
  }

  &__map {
    margin-top: 4rem;

    @include mq.mq($from: large) {
      margin-top: 0;
    }
  }
}