@use "../mixins/mq";

.nav--social-nav {
	.nav {
		&__wrap {
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;
		}
		&__link {
			display: grid;
		}
	}
}
