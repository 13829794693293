.address {
  max-width: 32.4rem;
  --spacing-h4: 2rem;

  &__title {
    margin-bottom: 2rem;
  }

  &__line {
    display: block;
  }
}