[data-scroll-reveal] {
    transition: transform var(--animation-duration-slow) ease-out, opacity var(--animation-duration-slow) ease-out;
    opacity: 1;
    isolation: isolate;
}

   


 //   @for $i from 1 through 9 {
//        $nth: $i + 1;
//        @if $nth < 10 {
//            &:nth-child(#{$nth}) {
//               --transition-delay: #{$i * .0625}s;
//          }
//     } @else {
//        &:nth-child(n +#{$nth}) {
//           --transition-delay: #{$i * .0625}s;
//      }
// }    





.delay {
    @for $i from 1 through 10 {
        $value: $i * 100;
        // stuff
        &-#{$value} {
            transition-delay: #{$value}ms;
        }
    }
}




.faded-out {
    transform: translateY(10rem);
    opacity: 0;
}

.opacity-reveal {
    opacity: 0;
}

.show-after {
    display: none;
}