.spacing {
    
    &-before {
        margin-top: var(--spacing);
    }
    &-after {
        margin-bottom: var(--spacing);
    }

    &,
    &-auto {
        margin-top: var(--spacing);
        margin-bottom: var(--spacing);
    }

    &-auto {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

}