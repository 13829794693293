@use "../mixins/mq";

.facts {
  &__item {
    padding: 0 1.6rem;
    width: 100%;

    /*@include mq.mq($from: large) {
      padding-left: 10rem;
      padding-right: 10rem;
    }*/

    @include mq.mq($from: xWide) {
      max-width: 50vw;
      padding: 0;
    }
  }
}