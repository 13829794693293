@use "../mixins/mq";

.text {
    --heading-margin-bottom: calc(#{var(--heading-spacing)} * 1.3);

    &__body {
        margin: 0 1.9rem 0 2.8rem;

        &--offset-top {
            margin-top: 3rem;
        }

        &--offset-bottom {
            margin-bottom: 5rem;
            
            @include mq.mq($from: medium) {
                margin-bottom: 3rem;
            }
        }
    }

    &__header {

    }

    @include mq.mq($from: medium) {
    
        &__body {
            margin-left: 0;
           margin-right: 0;

          
        }
	}

}