@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	scroll-behavior: smooth;
	scroll-padding-top: 60px;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--body-font-family, vars.$body-font-family);
	font-size: var(--body-font-size);
	line-height: var(--body-line-height);
	color: var(--body-color);
	font-weight: var(--body-font-weight);
	letter-spacing: var(--body-letter-spacing);
	background-color: var(--body-bg-color);
	font-variant: none;

	hyphens: var(--hyphens-style);
	word-break: break-word;

	[lang=de-CH] & {
		--hyphens-style: auto;
	}

	[lang=fr-CH] & {
		--hyphens-style: manual;
	}
}
