@use "../mixins/mq";

.teasers {
  &__cta {
    margin-top: 4rem;

    @include mq.mq($from: medium) {
      margin-top: 2.4rem;
      text-align: right;
    }

    @include mq.mq($from: large) {
      margin-top: 5rem;
    }
  }

  &__grid {
    @include mq.mq($from: large) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      grid-template-areas: "teaserMain teaserHeading"
      "teaserMain teasersOthers";

      &--reverse {
        grid-template-areas: "teaserHeading teaserMain"
      "teasersOthers teaserMain";
      }
    }
  }

  &__heading {
    display: flex;
    align-items: end;
    grid-area: teaserHeading;

    @include mq.mq($from: large) {
      aspect-ratio: 1/.88;
      padding: 0 15% 15%;

      .teasers__grid--reverse & {
        padding: 0 30% 15% 0;
      }
    }
  }

  &__others {
    grid-area: teasersOthers;
  }

  &__title {
    font-size: 4rem;

    @include mq.mq($from: large) {
      font-size: 7.5rem;
    }
  }
}