@use "../mixins/mq";

.map {

  &--big {
    aspect-ratio: 328/450;

    @include mq.mq($from: medium) {
      aspect-ratio: 1608/650;
    }
  }

  &--small {
    aspect-ratio: 328/450;

    @include mq.mq($from: medium) {
      aspect-ratio: 770/445;
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;

    &--has-link {
      --wrapper-spacing: -1.5rem;
      margin-left: var(--wrapper-spacing);
      margin-right: var(--wrapper-spacing);

      @include mq.mq($from: medium) {
        --wrapper-spacing: -4rem;
      }

      @include mq.mq($from: large) {
        --wrapper-spacing: 13rem;
      }
    }

    &--has-link {
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  &__heading {
    .map__wrapper--has-link & {
      /*--wrapper-gap: 1.5rem;
      padding-left: var(--wrapper-gap);
      padding-right: var(--wrapper-gap);

      @include mq.mq($from: 1660px) {
        --wrapper-gap: 0;
      }*/
    }
  }

  &__info-window {
    --action-margin-right: 2rem;
    --spacing-text: 1rem;
    max-width: 27rem;
    padding-bottom: .8rem;
    padding-right: .8rem;

    font-family: var(--body-font-family);
    font-size: 1.6rem;

    .action {
      margin-bottom: 0.5rem;
    }
  }

  &__wrapper--has-link {
    //--wrapper-gap: 0;
  }
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
  padding: 2rem;
  box-shadow: none;
}

.gm-control-active > img {
  box-sizing: content-box;
  display: none;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
}

.gm-control-active > img:nth-child(1) {
  display:block;
}

.gm-control-active:hover > img:nth-child(1),
.gm-control-active:active > img:nth-child(1) {
  display:none;
}

.gm-control-active:hover > img:nth-child(2),
.gm-control-active:active > img:nth-child(3) {
  display:block;
}