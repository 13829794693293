@use "../mixins/mq";

.teaser-news {

    &__cards {
        margin-top: 4rem;
        display: flex;
        gap: 1.6rem;
        flex-direction: column;

        @include mq.mq($from: large) {
            gap: 2.4rem;
            flex-direction: row;
        }

        .card {
            --card-margin-top: 0;

            @include mq.mq($from: large) { 
                flex: 1;
                align-self: flex-start;
            }
        }
    }

    &__cta {
        display: flex;
        justify-content: flex-start;
        margin-top: 1.6rem;

        @include mq.mq($from: large) {
            justify-content: flex-end;
            margin-top: 1rem;
        }
    }
}