.heading {
    margin-bottom: var(--heading-margin-bottom);
    line-height: 1.2;
    font-weight: 300;

    &:after {
        display: block;
        min-width: 11.1rem;
        width: 2.75em;
        height: .1rem;
        background-image: var(--color-brand-linear);
        margin-top: var(--heading-spacing);
        margin-inline: var(--heading-decoration-margin-inline, 0);
        content: '';
    }
/*
    &:last-child {
        &:after {
            display: none;
        }
    }
*/
    & + * {
        margin-top: calc(#{var(--heading-spacing)} * 1.3);
    }

    &--center {
        text-align: center;
        --heading-decoration-margin-inline: auto;
    }


}