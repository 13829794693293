@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";


video,
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
	object-position: var(--focalpoint);
	transition: transform var(--animation-duration) var(--animation-ease);
	transform: var(--img-transform, scale(1));
	will-change: transform;
	image-rendering: -webkit-optimize-contrast;
	backface-visibility: hidden;
}

figcaption {
	font-size: inherit;
	margin-top: 2rem;
}

.media {
	margin-top: var(--spacing-media);
	&:first-child {
		--spacing-media: 0;
	}
}

.figure {
	position: relative;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	img {
		
	}

	&-author {
		aspect-ratio: 48/53;

		@include mq.mq($from: large) {
			aspect-ratio: 1/1;
		}
	}

	&-journal-heading {
		aspect-ratio: 1/1;

		@include mq.mq($from: large) {
			aspect-ratio: 926/820;
		}
	}


	&-three {
		height: 0;
		padding-bottom: 76%;
	}

	&-text-image {
		aspect-ratio: 272/385;

		@include mq.mq($from: large) {
			aspect-ratio: 52/79;
		}
	}

	&-fullscreen:after,
	&-cta:after {
		background-color: color.adjust(colors.$color-black, $alpha: -0.55);
	}

	&-teaser-big,
	&-teaser-small {
		aspect-ratio: 1/1;
	}

	&-footer-icon {
		aspect-ratio: 1/1;
		width: 10rem;
	}

	&-teaser-events {
		aspect-ratio: 68/45;

		@include mq.mq($from: large) {
			aspect-ratio: 1/1;
		}
	}

	&-teaser-news {
		aspect-ratio: 266/149;

		@include mq.mq($from: large) {
			aspect-ratio: 46/25;
		}
	}

	&-slider {
		height: 0;
		padding-bottom: 100%;

		@include mq.mq($from: medium) {
			padding-bottom: 77.56%;
		}
	}

	&-fullscreen {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&--with-half-overlay {
			&:after {
				@include mq.mq($from: large) {
					width: 46.5%;
				}
			}
		}
	}

	&-intro {
		aspect-ratio: 1/1;
		width: calc(100% + 2rem);
		transform: translateX(2rem);

		& + * {
			margin-top: 4rem;
		}

		@include mq.mq($from: large) {
			position: absolute;
			right: 0;
			top: 50%;
			width: calc(50% - 2rem);
			transform: translateY(-50%);
			aspect-ratio: 935/760;

			& + * {
				margin-top: 0;
			}
		}
	}

	&-teaser-products {
		aspect-ratio: 1/1;
		width: calc(100%);
		max-width: 34.8rem;
		transform: translateX(-2rem);
		height: 100%;

		@include mq.mq($from: medium) {
			position: absolute;
			width: 50%;
			top: 0;
			left: 0;
			max-width: 95.5rem;
			z-index: -1;
			transform: translateX(0);
			height: 100%;
		}
		
		.applications--reverse & {
			transform: translateX(1.6rem);
			margin-left: auto;
			
			@include mq.mq($from: medium) {
				right: 0;
				left: unset;
				transform: translateX(0);
			}
		}
	}

	&-cta-contact {
		aspect-ratio: 36/45;

		@include mq.mq($from: large) {
			aspect-ratio: 38.2/55.2;
		}
	}
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}
