@use "../mixins/mq";

.link-big {
    display: flex;
    gap: 5rem;
    justify-content: space-between;
    align-items: center;
    color: var(--color-black);
    text-decoration: none;
    background-color: var(--color-white);
    padding: 1.3rem 2.2rem;
    min-height: 9rem;

    @include mq.mq($from: medium, $until: large) {
        gap: 3rem;
    }

    .map__container & {
        position: absolute;
        left: 50%;
        top: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
        //transform: translateY(-50%);
        align-self: center;
        max-width: calc(100vw - 3rem);
        z-index: 2;

        @include mq.mq($from: medium) {
            max-width: 67rem;
        }
    }

    &--brand {
        background-image: var(--link-big-bg-img);
        color: var(--color-white);
        padding: 1.8rem;

        @include mq.mq($from: large) {
            //padding: 5rem;
            padding: 3.5rem;
        }
    }

    &__description {

    }

    &__info {
        display: block;
        text-transform: uppercase;
        font-size: 1.6rem;
    }

    &__meta {
        display: block;
        font-size: 1.4rem;

        @include mq.mq($from: large ) {
            font-size: 1.6rem;
        }
    }

    &__title {
        display: block;
        font-size: var(--link-big-font-size);
        font-weight: 300;

        &--default {
            --link-big-font-size: unset;
            --spacing-text: 0.5rem;
            --spacing-h4: 0.5rem;
            font-weight: unset;
        }
    }

    &--empty {
        background-color: transparent;
        display: none;

        @include mq.mq($from: medium) {
            display: block;
        }
    }

    @include mq.mq($from: large ) {

        min-height: 10rem;
        
        &__title {

            &--default {
                font-size: unset;
            }
    
        }
    }

}
