/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
iframe {
  display: block;
}

iframe {
  border: 0;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;
}

* {
  box-sizing: border-box;
}

address {
  font-style: normal;
}

select {
  font-family: inherit;
}

/*
select, textarea, input, a, button {
  outline-color: var(--outline-color,currentColor);
  outline-offset: 0.3rem;

}
*/

.link,
a {
  color: var(--link-color, inherit);
  text-decoration: var(--link-text-decoration, none);
  transition: color var(--animation-duration) var(--animation-ease);
  will-change: color;

  &:focus,
  &:hover {
    --link-text-decoration: var(--link-text-decoration-active, underline);
    --link-color: var(--link-color-active);
  }
}

:focus-visible {
  outline-style: solid;
  outline-color: var(--outline-color, currentColor);
  outline-offset: var(--outline-offset, 0.3rem);
  outline-width: var(--outline-width, 0.1rem);
}

img {
  user-select: none;
  display: block;
  max-width: 100%;
}

legend {

  width: 100%;

}

input,
select,
textarea,
button {
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  border: none;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
  letter-spacing: inherit;
}

select {
  background-image: var(--select-bg);
  background-repeat: no-repeat;
  background-position: center right .5rem;
  background-size: .5em;
  padding-right: 1.2em;
  background-color: transparent;
  color: inherit;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-search-results-button {
  -webkit-appearance: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

::-webkit-search-results-button {
  -webkit-appearance: none;
  display: none;
}

::-webkit-details-marker {
  -webkit-appearance: none;
  display: none;
}

details,
details summary {
  padding: 0;
  background-image: none;
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

body {
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--scrollbar-color);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
    border-radius: 5rem;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: 5rem;
  }
}