@use "../mixins/mq";
@use "../utils/visually-hidden";

.overlay-header {
  --header-color: var(--color-white);
  --header-bg-color-opacity: 0;
  --header-margin-bottom: calc( var(--header-height) * -1);
}

.header {
  position: sticky;
  z-index: 50;
  top: 0;
  color: var(--header-color, inherit);
  width: 100%;
  background-color: rgba(255,255,255, var(--header-bg-color-opacity, 1));
  transition: 
    transform var(--animation-duration) var(--animation-ease), 
    border-color var(--animation-duration) var(--animation-ease),
    background-color var(--animation-duration) var(--animation-ease);
  transform: var(--header-transform,  translateY(0));
  will-change: transition, background-color;
  padding: 0;
  border-bottom: .1rem solid rgba(217,217,217, var(--header-border-bottom-color-opacity, 0));


  margin-bottom: var(--header-margin-bottom, 0);

  filter: drop-shadow(0 .3rem .6rem rgba(0, 0, 0, 0.04)) drop-shadow(0 1.1rem 1.1rem rgba(0, 0, 0, 0.04)) drop-shadow(0 2.5rem 1.5rem rgba(0, 0, 0, 0.02)) drop-shadow(0 4.5rem 1.8rem rgba(0, 0, 0, 0.01));


  &--menu-open,
  &--detached {
    --header-bg-color-opacity: 1;
    --header-color: currentColor;
  }
  &--detached {
    --header-border-bottom-color-opacity: 1;
  }

  &__outer { 
    padding: 1.3rem 0;
    transition: transform var(--animation-duration) var(--animation-ease);
    transform: var(--header-outer-transform, translateY(0));
    will-change: transition;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__logo {
    display: block;
    width: auto;
    height: 3.7rem;

    &--green {
      color: var(--color-black);

      .overlay-header & {
        color: var(--color-white);
      }

      .header--detached & {
        color: var(--color-black);
      }
    }

  }

    &__home-link {
      z-index: 2;
      position: relative;
      color: inherit;
      transform: var(--header-logo-transform, scale(1));
      transition: transform var(--animation-duration) var(--animation-ease);
      will-change: transition;
      transform-origin: left center;
    }

  &__hamburger {
    position: relative;
    z-index: 2;
  }


  &__nav {
    //medium small and medium only
    @include mq.mq($until: large) {
      position: fixed;
      top: 0;
      left: 0;
      width: calc(100vw - 5rem);
      max-width: 37.5rem;
      height: 100vh;
      //padding: 9rem var(--wrapper-gap) 5rem;
      padding: 9rem 0 5rem;
      padding-bottom: 15rem;
      display: block;
      z-index: 1;
      transform: translate3d(-100%, 0, 0);
      background-color: var(--color-white);
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      transition: transform var(--animation-duration) var(--animation-ease);
      color: var(--color-black);
      .header__nav--opened & {
        transform: translate3d(0, 0, 0);
      }

    }
    //medium only 
    /*
    @include mq.mq($from: medium, $until: large) {
      
    }
    */
  }

  &__nav-cta {
    padding: 4rem var(--wrapper-gap) 3rem;
  }

  &__nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-black-transparent);
    display: none;
    z-index: -1;

    .header__nav--opened & {
      @include mq.mq($until: large) {
        display: block;
      }
    }
  }



  // large
  @include mq.mq($from: large ) {

    --header-logo-transform: scale(1.5);

    height: var(--header-height);


  
    &__outer { 
      padding: 1.5rem 0;
    }

    &__hamburger {
      display: none;
    }

    &__logo {
      height: 5.7rem;
    }

    &__nav-cta {
      display: none;
    }


    &--scroll {
      --header-transform: translateY(-2.9rem);
      --header-outer-transform: translateY(-0.5rem);
      --header-logo-transform: scale(1) translateY(2rem);
      --header-nav-meta-opacity: 0;
      --header-nav-meta-pointer-events: none;
    }

    &__nav {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0;
      flex-direction: column-reverse;
      position: relative;
      gap: 1.4rem;
      height: 9rem;
    }

    &__nav-meta {
      //position: absolute;
      //bottom: 100%;
      //right: 0;
      opacity: var(--header-nav-meta-opacity,1);
      pointer-events: var(--header-nav-meta-pointer-events, all);

      transition: opacity var(--animation-duration) var(--animation-ease);
      will-change: opacity;
      display: flex;
      min-height: 2.5rem;

    }

  }


}