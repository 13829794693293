@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

:root {

    --form-row-gap: 0 3.3rem;

    --field-margin-bottom: 3rem;

    --field-error-font-size: 1.3rem;
    --field-error-line-height: 1.5;
    --field-error-color: var(--color-error);
    --field-error-margin: .5rem 0 0;

    --label-font-size: 1.6rem;
    --label-line-height: 1.2;

    --input-font-size: 1.6rem;

    --input-line-height: 1.2;
    --input-padding-inline: 1.2rem;
    --input-padding-block: 0;
    --input-border-color: currentColor;
    --input-border-width: 0 0 .1rem 0;
    --input-outline-border-width:.1rem;
    --input-outline-padding-inline: 1.2rem;
    --input-outline-padding-block: 2rem;
    --input-border-radius: 0;
    --input-background-color: transparent;
    --input-transition: color var(--animation-duration) var(--animation-ease),  border-color var(--animation-duration) var(--animation-ease);
    --input-select-padding-right: 2rem;

    @include background-svg.to-custom-property(svg.$svg-select-arrow, colors.$color-black, --input-select-arrow-black);
    @include background-svg.to-custom-property(svg.$svg-select-arrow, colors.$color-error, --input-select-arrow-error);
    @include background-svg.to-custom-property(svg.$svg-select-arrow, colors.$color-white, --input-select-arrow-white);
    --input-select-arrow-bg: var(--input-select-arrow-black);
 



    --check-size: 1.6rem;
    --check-color: currentColor;
    --check-border-width: .1rem;
    --check-border-color: currentColor;
    --check-transition: color var(--animation-duration) var(--animation-ease),  border-color var(--animation-duration) var(--animation-ease);
    --label-option-padding-left: calc( var(--check-size) + 1rem);
    --label-option-font-size: 1.6rem;
    --check-bg-color-checked: currentColor;

    @include background-svg.to-custom-property(svg.$svg-checkbox-tick, colors.$color-black, --check-bg-image-black);
    @include background-svg.to-custom-property(svg.$svg-checkbox-tick, colors.$color-white, --check-bg-image-white);

    --check-bg-image-checked: var(--check-bg-image-white);

}

.dark,
.section--dark {
    --input-select-arrow-bg: var(--input-select-arrow-white);
    --check-bg-image-checked: var(--check-bg-image-black);
}

.error,
.fui-error {
    --label-color: var(--color-error);
    --input-select-arrow-bg: var(--input-select-arrow-error);
    --input-border-color: var(--color-error);
    --input-color: var(--color-error);
}


.form {
    &__row {
        display: flex;
        gap: var(--form-row-gap,0);
        flex-wrap: wrap;
        flex-direction: column;

        @include mq.mq($from: medium) {
            flex-direction: row;
        }

        &--space {
            justify-content: space-between;    
        }
    }

    &__nested-field {
        padding: 1rem;
        background: rgba(127,127,127,.3);
        margin: 1.6rem -1rem;
    }

    &__actions {
        display: flex;
        gap: 1.6rem;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

//group for label and input
.field {
    flex: 1;
    margin-bottom: var(--field-margin-bottom);

    &__errors,
    &__error {
        list-style: none;
        font-size: var(--field-error-font-size);
        line-height: var(--field-error-line-height);
        color: var(--field-error-color, currentColor);
        margin: var(--field-error-margin);
    }
    &__option-group {
        display: grid;
        gap: 1rem;
    }

    &__group {
        position: relative;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 1.5rem;
        align-items: center;
        justify-content: center;
        bottom: 0;
        display: grid;
        width: 1.6rem;

        ~ .input {
            padding-left: 4.5rem;
        }
    }


    &--floating-label {
        color: blue;
    }

}


.input {
    // Resets
    font-family: inherit;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    margin: 0;
    appearance: none;
    color: var(--input-color, currentColor);
    width: 100%;
    display: block;
    padding: var(--input-padding-inline) var(--input-padding-block);

    border: 1px solid var(--input-border-color);
    border-width: var(--input-border-width,1rem);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    background-clip: padding-box;
    transition: var(--input-transition);


    &--outline {
        border-width: var(--input-outline-border-width);
        --input-padding-inline: var(--input-outline-padding-inline);
        --input-padding-block: var(--input-outline-padding-block);
        --input-select-arrow-bg-position: right 1rem center;
        --input-select-padding-right: 3.5rem;
    }

    &--dark {
        --input-background-color: var(--color-black);
        --input-color: var(--color-white);
    }

    &--white {
        --input-background-color: var(--color-white);
        --input-color: var(--color-black);
    }

    &--file {
        &::file-selector-button {
            color: var(--color-white);
            padding: 0 1rem;
            margin: -1.2rem 1.5rem -1.2rem 0;
            isolation: isolate;
            border: 0;
            appearance: none;
            height: 4.4rem;
            text-align: center;
            font-size: var(--input-font-size);
            background-image: var(--action-bg-img);
        }
    }

    &--dropdown,
    &--select {
        //icon

        &:not([multiple]):not([size]) {
            padding-right: var(--input-select-padding-right);
            background-position: var(--input-select-arrow-bg-position,right center);
            background-repeat: no-repeat;
            background-size: 1.6rem auto;
            background-image: var(--input-select-arrow-bg);
        }

        &[size],
        &[multiple] {
            height: auto;
        }


    }
    /* don't hide it, needed for screen reader */
    &--radio,
    &--checkbox {
        position: absolute;
        width: var(--check-size);
        height: var(--check-size);
        margin: .5rem 0;
        padding: 0;
        background: none;
        border: none;
    }
    &--radio {
        border-radius: 50%;
    }

    &--textarea {
        min-height: none;
        resize: vertical;

    }

    &--small-spacing::placeholder {
        letter-spacing: 0;
    }
}


.label {
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    color: var(--label-color, currentColor);

    &--legend {
        margin-bottom: 1rem;
    }

    &--option {
        display: block;
        padding: .5rem 0;
        padding-left: var(--label-option-padding-left);
        font-size: var(--label-option-font-size, inherit);
        position: relative;
        min-height: var(--check-size);
        line-height: var(--check-size);
        user-select: none;
        cursor: pointer;

        &::before {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            width: var(--check-size);
            height: var(--check-size);
            border: var(--check-color) var(--check-border-width) solid;
            background: var(--check-bg-color,transparent);
            background-image: var(--check-bg-image,none);
            transition: var(--check-transition);
            border-radius: .4rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            [type=radio] + & {
                border-radius: 50%;
                background-image: none;
            }


            :checked + & {
                --check-bg-color: var(--check-bg-color-checked);
                --check-bg-image: var(--check-bg-image-checked);
            }
        }
    }


}

.form__login {
	background-color: var(--color-white);
	padding: 3.2rem 1.6rem;

	@include mq.mq($from: large) {
		padding: 3.2rem;
	}
    .error {
        color: var(--color-error);
    }
    .notice {
        color: var(--color-success);
    }
    .row__text {
        font-size: 1.4rem;
        @include mq.mq($from: large) {
            font-size: 1.6rem;
        }
    }

    .form__row {
        flex-direction: row;
    }
}

.form__logout {
    &--action {
        font-size: 1.4rem;
        @include mq.mq($from: large) {
            font-size: 1.6rem;
        }
    }
}