@use "../mixins/mq";
@use "../atoms/heading";
@use "../_base/typography";


.cta-contact {

    &__section.section--dark {
        background-image: unset;

        @include mq.mq($from: medium) {
            background-image: var(--color-black-linear);
        }
    }

    &__wrapper {
        background-color: var(--color-white);

        &--has-link {
            padding-bottom: 6rem;

            @include mq.mq($from: medium) {
                padding-bottom: 9rem;
            }
        }

        @include mq.mq($from: medium) {
            background-color: transparent;
        }

        .section--dark & {
            background-image: var(--color-black-linear);

            @include mq.mq($from: medium) {
                background-image: unset;
            }
        }
    }
    &__row {
        background-color: var(--color-white);
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        position: relative;

        @include mq.mq($from: medium) {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: var(--grid-gap);
        }
        @include mq.mq($from: large) {
            padding-top: 7rem;
            padding-bottom: 7rem;
        }

        &--media {
            @include mq.mq($from: medium) {
                grid-template-rows: min-content 3rem;
            }
            @include mq.mq($from: large) {
                grid-template-rows: min-content 15.1rem;
            }   
        }

        .section--dark & {
            background-color: transparent;
        }
        
    }

    &__text {
        //margin-bottom: -2rem;
        

        @include mq.mq($from: medium) {
            grid-column: 2 / 6;
            //margin-bottom: 2rem;
        }
        @include mq.mq($from: large) {
            //margin-bottom: 5rem;
        }

        &--media {
            @include mq.mq($from: medium) {
                grid-column: 2 / 8;
                margin-bottom: 2rem;
            }   
        }

        .h2 {
            font-size: 4rem;
            @include mq.mq($from: xLarge) {
                font-size: 7.5rem;
            }
        }

        .teaser {
            padding: 0 2.8rem 0;

            @include mq.mq($from: medium) {
                padding: 0;
            }
            + * {
                margin-top: 4rem;
            }
        }
    }

    &__image {
        margin-left: -1.6rem;
        margin-right: -1.6rem;

        @include mq.mq($from: medium) {
            grid-column: 9 / 12;
        }
    }

    &__link {
        --action-fab-outline-color: var(--color-white);
        --action-fab-outline-active-color: var(--color-black);
        --action-fab-outline-bg-color: var(--color-white);
        width: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
        
        transform: translateY(6.8rem);

        @include mq.mq($from: medium) {
            //transform: translateY(15rem);
            position: absolute;
            right: 0;
            bottom: -5rem;
            transform: translateY(0);
            grid-column: 7 / 12;
        }

        @include mq.mq($from: large) {
            bottom: -9rem;
        }
        
        &--media {
            width: 100%;
            width: -moz-available;
            width: -webkit-fill-available;
            width: fill-available;
            transform: translateY(-7.8rem);

            @include mq.mq($from: medium) {
                grid-column: 2 / 7;
                transform: translateY(0);
                bottom: -7rem;
                left: -2rem;
            }
            @include mq.mq($from: large) {
                left: -3rem;
            }
            @include mq.mq($from: xLarge) {
                left: -5rem;
            }
        }

        &--icons {

            .section--dark & {
                bottom: 5rem;
            }
        }

        
        &--title {
            font-family: var(--body-font-family);
            font-size: 2rem;
            font-weight: 600;
            line-height: 130%; 
            letter-spacing: 0.65px;

            @include mq.mq($from: xLarge) {
                font-size: 3.4rem;
                letter-spacing: 0.8px;
            }

          
        }
        &--text {
            font-family: var(--body-font-family);
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.85px;
            
            @include mq.mq($from: xLarge) {
                font-size: 2.4rem;
                font-weight: 300;
                line-height: 130%;
                letter-spacing: 1.2px;
            }
           
        }
        
        .link-big {
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        
    }

    &__actions {
        --icon-width: 3.6rem;
        --icon-height: 3.6rem;

        display: grid;
        gap: 5rem 3rem;
        grid-template-columns: 1fr 1fr;

        @include mq.mq($from: large) {
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 10rem;
        }
    }
}
.action-item {
    &__title {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.2;
        display: block;
        margin-top: 2rem;
    }
    &__link, &__text {
        color: var(--color-black);
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.085rem;
        white-space: nowrap;

        @include mq.mq($from: large) {
            font-size: 1.6rem;
        }

        .section--dark & {
            color: var(--color-white);
        }
    }
}