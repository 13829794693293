@use "../mixins/mq";


.filters {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
  //overflow: visible;
  -webkit-overflow-scrolling: touch;

  /*@include mq.mq($from: large) {
    margin-bottom: 6rem;
  }*/

  &__field {
    width: 100%;

    @include mq.mq($from: large) {
      width: auto;

      &--long {
        min-width: 30rem;
      }
      &--xllong {
        min-width: 48rem;
      }
    }
  }

  &--events {
    flex-wrap: wrap;
    overflow: visible;
  }

  &--products {
    flex-wrap: wrap;
    overflow: visible;

    .combo-menu {
      width: fit-content;
      color: var(--color-black);
    }
  }

  &__closer {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    width: 2rem;
    height: 2rem;

    .icon {
      width: 2rem;
      height: 2rem;
    }

    @include mq.mq($from: large) {
      display: none;
      visibility: hidden;
    }

    &--action {
      position: relative;
      width: 100%;
      
      @include mq.mq($from: large) {
        display: none;
        visibility: hidden;
      }
    }

  }

  &__header {
    overflow: visible;
    margin-bottom: 4rem;

    @include mq.mq($from: large) {
      position: absolute;
      right: 0;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 2rem;

    @include mq.mq($from: large) {
      display: none;
      visibility: hidden;
    }
  }

  &__grid {
    position: relative;

    .teasers-news-filters__section & {
      display: grid;
      gap: 1.6rem;
      grid-template-columns: 1fr;

      @include mq.mq($from: medium) {
        gap: 2rem;
        grid-template-columns: 1fr 1fr;
      }

      @include mq.mq($from: large) {
        gap: 2.4rem;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  &__load-more {
    margin-top: 8.4rem;
    text-align: center;
  }

  &__opener {
    width: 100%;
    font-size: 1.4rem;

    &--map {
      margin-bottom: 2rem;

      @include mq.mq($from: large) {
        margin-bottom: 0;
      }
    }

    @include mq.mq($from: large) {
      display: none;
      visibility: hidden;
    }
  }

  &__wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    padding: 1.6rem;
    background-color: var(--color-white);
    z-index: 9999;
    transform: translateX(100%);
    transition: transform var(--animation-duration) var(--animation-ease);

    .filters__wrapper--opened & {
      transform: translateX(0);
    }

    @include mq.mq($from: large) {
      z-index: 3;
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      height: auto;
      padding: 0;
      transform: none;
      background: none;
      overflow: visible;
    }
  }
}