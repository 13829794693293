@use "../mixins/mq";

.logos {
	&__figure {
		width: 100%;
		text-align: center;
		filter: url(#GrayScaleFilter);
		transition: var(--animation-duration) var(--animation-ease);

		&:hover {
			filter: grayscale(0);
		}
	}

	&__list {
		--list-margin-left: 0;
		--list-margin-top: 0;
	}

	&__logo {
		--list-item-margin-top: 0;
		width: 100%;
		height: 12.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 0;
		line-height: 0;
		background-color: var(--color-white);
	}

	&__pagination {
		bottom: -3.5rem;
		display: flex;
		align-items: stretch;
		justify-content: stretch;
		height: 1px;
		width: 115px;
		margin: 0 auto;
		padding: 0;

		@include mq.mq($from: large) {
			bottom: -4rem;
		}

		li {
			--list-item-padding-left: 0;
			display: block;
			height: 100%;
			line-height: 0;
			flex: 1;
			background-color: var(--color-black);

			&.is-active ~ li {
				background-color: var(--color-disabled);
			}
		}


		&__page {
			display: block;
			width: 100%;
			height: 100%;
			opacity: 1;
			margin: 0;
			border-radius: 0;
			background-color: inherit;

			&.is-active {
				transform: unset;
				background-color: inherit;
			}
		}
	}

	&__slider {
		margin-bottom: 3.5rem;

		@include mq.mq($from: large) {
			margin-bottom: 4rem;
		}
	}

	&__svg {
		width: 100%;
		height: auto;
		max-height: 7.5rem;
		color: var(--color-grey-600);
	}
}
