@use "../mixins/mq";
@use "../_base/typography";

.nav--footer-nav {
	.nav {
		&__wrap {
			&--parent {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 6rem var(--grid-gap);
			}
			&--child {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 2rem 0;
			}
		}

		&__link--parent {
			@extend .h4;
		}

		&__item {
			
			&--parent {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 2rem 0;
			}
		}


		@include mq.mq($from: large) {
			&__wrap {
				&--parent {
					grid-template-columns: repeat(5, 16rem);
					gap: 3rem 10rem;
					gap: 3rem 8%;
				}
			}
		}
	}


}