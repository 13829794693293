@use "../mixins/mq";

.news-heading {
    overflow: hidden;
    background-color: unset;

    &__introduction, &__meta, &__info {
        margin-top: 3rem;

        @include mq.mq($from: large) {
            margin-top: 6rem;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }

    &__author-image {
        width: 4.8rem;

        @include mq.mq($from: large) {
            width: 8.2rem;
        }
      }
    
    &__author-name {
        font-size: 1.6rem;

        @include mq.mq($from: large) {
            width: 2rem;
        }
    }

    &__info-date {
        font-size: 1.4rem;

        @include mq.mq($from: large) {
            width: 2rem;
        }
    }
  
    
}