@use "../mixins/mq";
@use "../_base/typography";


.nav--main-nav {
	--nav-link-min-height: 5rem;
	--link-color-active: var(--color-primary-1);
	--link-text-decoration-active: none;
	position: relative;

	.header__nav-meta {
		display: flex;
		justify-content: space-between;
	}

	.nav {

	

		&__back-link {
			display: flex;
			font-size: 1.8rem;
			font-weight: 600;
			align-items: center;
			gap: 0.7rem;
			width: 100%;
			letter-spacing: .075rem;
			margin-bottom: 1rem;
		}
		&__back-link-text {
			text-align: left;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&__item {
			display: flex;
			&:hover > a {
				--link-color: var(--link-color-active);
			}
			.action {
				&:after {
					display: none;
				}
			}

			&.green-steel-item {
				--link-color-active: var(--color-secondary-1);
		
				.nav__link--parent:after {
					background-color: var(--color-secondary-1);
					background-image: var(--color-secondary-linear);
				}
			}
		}

		

		&__wrap {
			
			padding: 0;
			&--child {
				box-shadow: 0px 20rem 0px 0px var(--color-white);
			}
		}

		&__link {
			display: flex;
			min-height: var(--nav-link-min-height);
			padding: var(--main-nav-link-padding, 0);
			align-items: center;
			justify-content: space-between;
			width: 100%;
			gap: 0 1rem;

			&--parent {
				font-size: 1.8rem;
				position: relative;

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 100%;
					height: .3rem;
					left: 2.5rem;
					right: 2.5rem;
					background-color: var(--color-brand-1);
					background-image: var(--color-brand-linear);
					opacity: 0;
				}
				&[aria-expanded=true] {
					&:after {
						opacity: 1;
					}
				}

			}

			&-icon {
				display: none;
				flex: none;
			}

		}

		&__toggle {

			min-height: var(--nav-link-min-height);
			aspect-ratio: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
	

		}
	}

	//mobile only
	@include mq.mq($until: large) {
		//height: var(--nav-height, auto);
		height: var(--nav-height, auto);
		min-height: 4rem;
		overflow: hidden;
		padding: .5rem 0;
		--nav-link-min-height: 7.3rem;

		transition: height var(--animation-duration) var(--animation-ease);
		will-change: height;

		.action {
			display: none;
		}
	

		.nav {
		
			&__wrap {
				z-index: 2;
				position: relative;
				background-color: var(--color-white);
				padding: 0 var(--wrapper-gap);

				&--child {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					transform: translateX(100%);
					transition: transform var(--animation-duration) var(--animation-ease);
					will-change: transform;
					--nav-link-min-height: 4.7rem;
					font-size: 1.4rem;
					visibility: hidden;
				}

				&--current  {
					transform: translateX(0%);
					--nav-link-transform: translateX(0); 
				}
				&--visible {
					visibility: visible;
				}

				&:has( .nav__wrap--current ) {
					--nav-link-transform: translateX(-25%); 
				}

				/*
				&--active{
					//transform: translateX(-100%);
					--nav-link-transform: translateX(-25%); 
		
				}
				*/
			}


			&__item {
				border-bottom: var(--color-grey-200) .1rem solid;
			}

			&__back-link,
			&__link {
				transform: var(--nav-link-transform, translateX(0%) );
				transition: transform var(--animation-duration) var(--animation-ease);
				will-change: transform;
			}
		}

	}

	//large
	@include mq.mq($from: large) {
		--main-nav-link-padding: 0 2.5rem;
		--main-nav-child-width: 28rem;

		.nav {
			&__link {
				
				&-icon {
					display: block;
				}
	
			}
			&__item {
				

				&--parent {
					//position: relative;
					/*&:last-child {
						--main-nav-link-padding: 0 0 0 2.5rem;
					}*/

			
					
				}

				&--has-children {
					
				}
			}

			&__wrap {
				display: inline-flex;
				gap: 0;
				max-height: none;
	
				&--parent {
					&:after {
						content: "";
						display: none;
						background-color: #fff;
						top: 100%;
						left: 0;
						position: absolute;
						height: var(--main-nav-backdrop-height, 0rem);
						right: -4rem;
						left: -4rem;
						z-index: -1;
						padding-top: 1.2rem;
			
						.header--menu-open & {
							display: block;
						}

					}

				


				}

				&--child {
					--main-nav-link-font-size: 1.6rem;
					--main-nav-link-line-height: 1.2;
					--nav-link-min-height: none;
					//--main-nav-toggle-display: flex;
					--nav-link-min-height: 4.7rem;
					--main-nav-link-padding: 1.6rem 3rem;
					overflow: visible;
					padding: 1rem 0;
					font-weight: 600;
					position: absolute;
					width: var(--main-nav-child-width);
					color: var(--color-black);
					//display: flex;
					
					flex-direction: column;
					//background-color: var(--color-white);
			
					top: var(--main-nav-wrap-child-top,100%);
					left: var(--main-nav-wrap-child-left,-.5rem);
					box-shadow: none;
					border: solid .01rem var(--color-grey-300);
					border-width: 0 .01rem 0 0;
					display: none;

					//animation: fade-in var(--animation-duration) var(--animation-ease) ;
	

				
				

			
					.nav__wrap--child {
						--main-nav-wrap-child-top: 0;
						--main-nav-wrap-child-left: 100%;
						--main-nav-link-padding: 1.2rem 3rem;
			
						font-weight: 400;
						border-width: 0 0 0 .01rem;
					}

				}
			}

			&__item--back-link {
				display: none;
			}

			&__wrap--visible {
				//display: block;
			}

			&__toggle {
				//check for accesibilty
				display: var(--main-nav-toggle-display, none);
			}

			&__link {
				font-size: var(--main-nav-link-font-size,2rem);
				line-height:var(--main-nav-link-line-height,1.6rem);
			}
			
			&--child {
				font-weight: 600;

			}

		}
	}

}

/*
.nav--main-nav {
	.nav {
		&__item--parent {
			position: relative;
			//max-height: 7rem;
			width: 100%;
			margin: 0 2rem;
			//transition: max-height 0.5s var(--animation-ease);
			//overflow: hidden;
	

			@include mq.mq($from: large) {
				width: auto;
				margin-left: 6rem;
				margin-right: 0;
				//overflow: visible;
				border-bottom: 0;
			}
		}

		&__item--child {
			margin-bottom: 2rem;

			@include mq.mq($from: large) {
				margin-bottom: 0;
			}
		}

		&__link {
			@extend .font-weight-700;

			&--parent {
				display: flex;
				min-height: 5rem;
				padding: 1rem 4rem 1rem 0;
				align-items: center;
				font-size: 2.4rem;
				line-height: 3.8rem;

				@include mq.mq($from: large) {
					height: 7rem;
					padding: 0;
				}

				.not-touch & {
					&:hover {
						color: var(--color-primary-1);
					}
				}
			}

			&--child {
				display: block;
			}
		}
	}
}
*/
/*
@keyframes fade-in {
    0% {
       opacity: 0;
    }

    100% {
		opacity: 1;
    }
}
*/