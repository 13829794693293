.contact-cta {
    position: sticky;
    isolation: isolate;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: block;
    height: 0;
    text-align: right;
    transform: translate(-2rem, -7rem);
    


    &__content {
        position: relative;
        display: inline-block;
    }
    
    height: 0;
    text-align: right;
  
    &__button {
        user-select: none;
        max-width: 5.5rem;
    }

    &__dropdown {
        display: var(--contact-cta-dropdown-display,block);
        position: absolute;
        background-color: var(--color-white);
        box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
        right: 7rem;
        list-style: none;
        padding: 0;
        bottom: 0;
        margin: 0;
        white-space: nowrap;
        min-width: 27rem;
        text-align: left;
        
    }

    &__item {
        margin: 0;
        padding: .6rem 1.6rem;
        
        &--separator {
            border-top: var(--color-grey-200) solid .1rem;
        }
    }
}