@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

.product-finder {
    
    &__search {
        margin: 3rem 0;
    }

    &__table-header {
        background-color: var(--color-grey-600);
        display: none;

        @include mq.mq($from: large) {
            display: block;
        }

        .table-header__inner {
            @include mq.mq($from: large) {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 0 0.8rem;
                width: calc(100% - 2rem);
                align-items: center;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                padding-left: 2rem; 
            }
        }
        
    }

    .action-clear {
        --icon-color: var(--color-white);

        @include mq.mq($from: large) {
            transform: translateY(-4rem);
        }
    }

}

.product-item {

    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    border-top: 1px solid var(--color-grey-600);
    border-bottom: 1px solid var(--color-grey-600);
    position: relative;

    @include mq.mq($from: large) {
        padding-left: 2rem;
    }

/*    &::after {
        content: unset;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        @include background-svg.params(svg.$svg-chevron-down, transparent, center, center, no-repeat, 14px, 8px, colors.$color-white);
        position: absolute;
        top: 1.6rem;
        right: 0;
        z-index: 10;

    }*/

    &__properties {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem 0.8rem;
        margin-bottom: 0;
        width: calc(100% - 2rem);

        @include mq.mq($from: large) {
            grid-template-columns: repeat(6, 1fr);
            grid-template-areas: "portfolio family matc matn brand char";
        }

        .open & {
            margin-bottom: 2rem;
        }
        
    }

    &__description {
        font-size: 1.4rem;
        line-height: 1.2;
        letter-spacing: 0.85px;
        margin-bottom: 3rem;
        margin-right: auto;
        @include mq.mq($from: large) {
            grid-column: 1 / 4;
        }
    }

    &__links {

        margin-bottom: 3rem;
        
        @include mq.mq($from: large) {
            margin-bottom: 0;
            grid-column: 4 / 7;
        }
        
        &--flex {
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem 5rem;

            .action {
                @include mq.mq($from: large) { 
                    width: fit-content;
                }
            }
            

            @include mq.mq($from: large) { 
                width: 100%;
                gap: 5rem;
                justify-content: flex-end;
            }
        }

        &--break {
            flex-basis: 100%;
            height: 0;

            @include mq.mq($from: large) { 
                display: none;
            }
        }
        
        
    }

    &__meta {
        display: none;

        @include mq.mq($from: large) {
            margin-top: 3rem;
            align-items: center;
            grid-template-columns: 3fr 1fr 0.5fr;
            gap: 5rem;
        }
    }

    dl {
        dt {
            font-size: 1.2rem;
            font-weight: 600;
            display: none;

            @include mq.mq($from: large) {
                display: none!important;
            }
        }
        dd {
            font-size: 1.4rem;
            letter-spacing: 0.85px;
            line-height: 1.2;
        }
    }

    .accordion-trigger {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 1.6rem;
        right: 0.5rem;
        z-index: 10;
        padding: 6px 3px;
        @include background-svg.params(svg.$svg-chevron-down, transparent, center, center, no-repeat, 14px, 8px, colors.$color-white);
        transition: transform 0.2s ease-in-out;
    }

}

.accordion-panel {

    .accordion-closed {
        display: none;
        
        @include mq.mq($from: large) {
            display: block;
        }
    }

    &.open {
        .accordion-trigger {
            transform: rotate(-180deg);
        }

        dt {
            display: block;
        }

        .product-item__meta {
            display: block;

            @include mq.mq($from: large) {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 0 0.8rem;
                width: calc(100% - 2rem);
            }
        }

        .accordion-closed {
            display: block;
        }
    }
}

.pagination-list {
    display: flex;
    justify-content: flex-end;

    &__li {
        --list-item-margin-top: 2rem!important;
        
        border: 1px solid var(--color-white);
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.5;

        @include mq.mq($from: large) {
            --list-item-margin-top: 4rem!important;
        }

        &--empty {
            padding: 6px 12px;
            display: block;    
        }
        
    }

    &__page {
        color: var(--color-white);
        text-decoration: none;
        padding: 6px 12px;
        display: block;

        &.active, &:hover {
            color: var(--color-black);
            background-color: var(--color-white);
        }
        &[aria-disabled="true"] {
            pointer-events: none;
            text-decoration: none;
            opacity: 0.5;
        }

    }
}

.property {

    @include mq.mq($from: large) {
        &__portfolio {
            grid-area: portfolio;
        }
        &__family {
            grid-area: family;
        }
        &__matc {
            grid-area: matc;
        }
        &__matn {
            grid-area: matn;
        }
        &__brand {
            grid-area: brand;
        }
        &__char {
            grid-area: char;
        }   
    }
}