$fontPath: '../../webfonts/';


  @font-face{
    font-family: 'Source Sans 3';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-ExtraLight.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-ExtraLightIt.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-Light.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-LightIt.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-Regular.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-It.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-Semibold.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-SemiboldIt.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-Bold.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-BoldIt.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-Black.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    src: url('#{$fontPath}SourceSans3/SourceSans3-BlackIt.ttf.woff2') format('woff2');
}
