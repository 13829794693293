@use "../mixins/mq";

.section {
	position: relative;
	//is this needed ?
	//overflow: hidden;
	margin-top: var(--section-spacing-top, 0);
	margin-bottom: var(--section-spacing-bottom, 0);

	&:last-child {
		--section-spacing-bottom: var(--section-spacing);
	}

	&--overflow-hidden {
		overflow: hidden;
	}

	&--spacing {
		--section-spacing-top: var(--section-spacing);

	}

	&--spacing-top {
		--section-spacing-top: var(--section-spacing);
	}
	&--spacing-bottom {
		--section-spacing-bottom: var(--section-spacing);
	}
	
	/* all section with bg colors */
	&--bg {
		&:first-child {
			--section-spacing-top: 0;
		}

		+ .section--bg {
			--section-spacing-top: 0;
		}
	}

	&--grey {
		padding: var(--section-spacing) 0;
		background-color: var(--color-grey-200);
		background-image: var(--color-grey-linear);

		&:last-child {
			--section-spacing-bottom: 0;
		}
	}


	&--dark {
		padding: var(--section-spacing) 0;
		//background-color: var(--color-black);
		background-image: var(--color-black-linear);
		--outline-color: var(--color-white);
		// ?? move this to a inveted class ?
		color: var(--color-white);

		--breadcrumb-color: var(--color-white);

		&:last-child {
			--section-spacing-bottom: 0;
		}
	}

	&__box {
		padding: var(--section-box-padding);
		margin: var(--section-box-margin);
		background-color: var(--section-box-bg-color);
	}

}


.section__title {
	margin-bottom: 4rem;
}
