.combo {
  display: block;
  position: relative;
}



.combo-input {
  display: block;
  font-size: 1.6rem;
  text-align: left;
  width: 100%;
}

select.combo-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*.open .combo-input {
  border-radius: 4px 4px 0 0;
}*/

.combo-input:focus {
 /* border-color: #0067b8;
  box-shadow: 0 0 4px 2px #0067b8;
  outline: 5px solid transparent;*/
}

.combo-label {
  display: block;
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 0.25em;
}

.combo-menu {
  background-color: #f5f5f5;
  border: 1px solid rgba(0,0,0,.42);
  border-radius: 0 0 4px 4px;
  display: none;
  max-height: 300px;
  overflow-y:scroll;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 100;
}

.open .combo-menu {
  display: block;
}

.combo-option {
  cursor: pointer;
  font-size: 1.6rem;
  padding: 10px 2rem 12px;
}

.combo-option.option-current,
.combo-option:hover {
  background-color: rgba(0,0,0,0.1);
}

.combo-option.option-selected {
  padding-right: 30px;
  position: relative;
}

.combo-option.option-selected::after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  content: '';
  height: 16px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  width: 8px;
}

/* multiselect list of selected options */
.selected-options {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.selected-options li {
  display: inline-block;
  margin-bottom: 5px;
  --list-item-padding-left: 0;
  --list-item-margin-top: 0;
}

.remove-option {
  background-color: var(--color-primary-1);
  border: 1px solid var(--color-primary-1);
  border-radius: 3px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0.2rem 2.5rem 0.2rem 0.3rem;
  position: relative;
}

.remove-option:focus {
  border-color: var(--color-primary-1);
  //box-shadow: 0 0 3px 1px var(--color-primary-1);
  outline: 3px solid transparent;
}

.remove-option::before,
.remove-option::after {
  border-right: 2px solid #fff;
  content: "";
  height: 1em;
  right: 0.75em;
  position: absolute;
  top: 50%;
  width: 0;
}

.remove-option::before {
  transform: translate(0, -50%) rotate(45deg);
}

.remove-option::after {
  transform: translate(0, -50%) rotate(-45deg);
}


.combo .multiselect-inline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  --input-select-padding-right: 0;
  --input-outline-padding-inline: 1.1rem;
}

.multiselect-inline .selected-options {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  gap: 0.5rem;
}

.multiselect-inline .selected-options li {
  margin-bottom: 0;
  display: inline-flex;
}

.multiselect-inline .combo-input {
  border: none;
  flex: 1 1 35%;
  padding: 0;
  line-height: 1;
}

.multiselect-inline .combo-input:focus {
  box-shadow: none;
  outline: none;
}

.multiselect-inline:focus-within {
  //box-shadow: 0 0 3px 2px currentColor;
 // outline: 5px solid transparent;
 outline-style: solid;
 outline-color: var(--outline-color,currentColor);
 outline-offset:var(--outline-offset,0.3rem);
 outline-width: var(--outline-width,0.1rem);
}