@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/mq";
@use "../mixins/background-svg";

.action {
	--icon-width: 1.7rem;
	--icon-height: 1.7rem;
	position: relative;
	display: inline-flex;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	margin-right: var(--action-margin-right);
	transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
	cursor: pointer;
	color: var(--action-color);
	border-color: var(--action-border-color);
	border-width: var(--action-border-width, .2rem);
	border-style: solid;
	border-image: var(--action-border-image) 1;
	border-radius: .1rem;
	padding: var(--action-padding,1.7rem);
	min-width: var(--action-min-width,18rem);
	font-size: 1.8rem;
    line-height: 1.112;
	font-weight: 600;
	z-index: 1;
	flex-shrink: 0;


	&:before {
		content: "";
		border-radius: .1rem;
		position: absolute;
		inset: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		opacity: var(--action-bg-opacity,1);
		background-color: var(--action-bg-color);
		background-image: var(--action-bg-img);
		transition: opacity var(--animation-duration) var(--animation-ease);
		will-change: opacity;

	}

	&[disabled],
	&.disabled,
	&--disabled,
	&:disabled {
		--action-color: var(--action-disable-color);
		--action-border-color: var(--action-disabled-border-color);
		--action-bg-img: var(--action-disabled-bg-img);
		--action-border-image:  var(--action-disabled-border-image);
		--action-bg-opacit: 0;
	}

	&__text {
		text-indent: var(--action-text-indent,normal);
		position: var(--action-text-position,static);
	}

	&--block {
		display: flex;
	}

}

.action--small {
	--action-padding: 1rem;
}

.action-fab,
.action-primary {
	a:hover &,
	a:focus &,
	&.active,
	&:hover,
	&:focus {
		--action-color: var(--action-active-color);
		--action-border-color: var(--action-active-border-color);
		//--action-bg-img: var(--action-active-bg-img);
		--action-bg-opacity: 0;
		--action-border-image:  var(--action-active-border-image);
	}
}



.action-secondary {
	&:not(.active):not(:hover):not(:focus):not([disabled]):not(.disabled):not(:disabled) {
		--action-color: var(--action-active-color);
		--action-border-color: var(--action-active-border-color);
		//--action-bg-img: var(--action-active-bg-img);
		--action-bg-opacity: 0;
		--action-border-image:  var(--action-active-border-image);
	}
}


.action-tertiary {
	--action-border-width: 0 0 .1rem 0;
	--action-min-width: none;
	--action-padding: 0.2rem 0;
	--action-bg-clip: text;
	--action-color: transparent; 
	--action-active-bg-img: var(--action-bg-img);
	--action-bg-opacity: 0;
	--icon-color: var(--color-black);

	background-image: var(--action-bg-img);

	path {
		fill: var(--fill-gradient);
	}

	
	-webkit-background-clip: text;
	background-clip: text;


	&[disabled],
	&.disabled,
	&--disabled,
	&:disabled {
		--fill-gradient: var(--action-disable-color);
	}

}

.action-fab-outline,
.action-fab {
	--action-min-width: none;
	//--action-text-indent: -9999rem;
	//--action-text-position: absolute;

	&[disabled],
	&.disabled,
	&--disabled,
	&:disabled {
		--action-color: var(--action-disable-color);
		--action-border-color: var(--action-disabled-border-color);
	}
}

.action-fab-outline {
	--action-min-width: none;
	//--action-text-indent: -9999rem;
	//--action-text-position: absolute;
	--action-color: var(--action-fab-outline-color);
	--action-border-color: var(--action-fab-outline-color);

	--action-border-image: none;
	--action-bg-img: none;
	--action-bg-color: var(--action-fab-outline-bg-color);
	--action-bg-opacity: 0;

	a:hover &,
	a:focus &,
	&.active,
	&:hover,
	&:focus {
		--action-bg-opacity: 1;
		--action-border-color: var(--action-fab-outline-color);
		--action-color: var(--action-fab-outline-active-color);
	}
}




.action-download {
	@extend .action;
	font-size: var(--font-size-button);
	@extend .font-weight-700;
	color: var(--color-black);

	&:before {
		width: 4rem;
		height: 4rem;
		content: '';
		margin-right: 1rem;
		@include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
	}
}

.action-dark {
	--action-border-width: 0.1rem;
	--action-border-color: var(--color-black);
	--action-border-image: none;
	min-width: unset;
	font-size: 1.6rem;
	font-weight: 400;
	--action-padding: 1.2rem 2.5rem;
	--action-bg-opacity: 0;
	--action-color: var(--color-black);
	--action-bg-img: none;
	--action-bg-color: var(--color-black);

	&.active,
	&--active {
		--action-color: var(--color-white);
		--action-bg-opacity: 1;
	}
}

.action-footer {
	color: var(--color-white);

	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}

.action-arrow {
	--action-color: var(--color-black);
	--action-bg-opacity: 0;
	justify-content: flex-start;
	width: 100%;
	gap: 1.6rem;
	padding-left: 0;
	font-size: 1.6rem;
	border: unset;

	.action__text {
		max-width: calc(100% - 4rem);
	}

	svg {
		transition: transform var(--animation-duration) var(--animation-ease);
	}

	&:hover {
		--action-color: var(--color-white);
		--action-bg-opacity: 1;

		svg {
			transform: translateX(0.8rem);
		}
	}
}

.action-clear {
	--action-border-width: 0 0 .1rem 0;
	--action-min-width: none;
	--action-padding: 0.2rem 0;
	--action-bg-clip: text;
	--action-border-color: transparent;
	--action-color: currentColor;
	--action-bg-img: unset;

	--action-bg-opacity: 0;
	--icon-color: var(--color-black);
	gap: 1rem;
	margin-top: 1.5rem;
	font-size: 1.6rem;
	font-weight: 400;
	border: none;

	@include mq.mq($from: large) {
		margin-top: 0;
	}

	.icon {
		display: none;
		visibility: hidden;

		@include mq.mq($from: large) {
			display: inline-flex;
			visibility: visible;
		}
	}
}

.action-fullwidth {
	width: 100%;
}

.action-fit {
	width: fit-content;
}

.parent-link {
	text-decoration: unset;
}