@use "../mixins/mq";

.journal-heading {
  overflow: hidden;
  background-color: var(--color-white);

  @include mq.mq($from: large) {
    background: unset;
  }

  &__anchors {
    margin-top: 5rem;
  }

  &__author-image {
    width: 4.8rem;
  }

  &__author-name {
    font-size: 1.6rem;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 7rem;

    &-text {
      line-height: 1.2;
    }

    &-date {
      font-size: 1.4rem;
    }
  }

  &__featured-image {
    margin-left: calc(-1 * #{var(--wrapper-gap)});
    margin-right: calc(-1 * #{var(--wrapper-gap)});

    @include mq.mq($from: medium) {
      margin-left: unset;
      margin-right: unset;
    }
  }

  &__introduction {
    margin-top: 5rem;
  }

  @include mq.mq($from: large) {
    --font-size-h4: 3.4rem;
  }

  &__title {
    font-size: 3.4rem;

    @include mq.mq($from: large) {
      font-size: 5.8rem;

    }
  }

  &__text {
    padding-top: 3rem;
    padding-bottom: 3.5rem;

    @include mq.mq($from: large) {
      position: relative;
      padding-top: 10rem;
      padding-bottom: 3rem;

      &:before {
        position: absolute;
        left: 50%;
        top: 0;
        display: block;
        content: '';
        height: 100%;
        width: 200vw;
        background-color: var(--color-white);
        transform: translateX(-50%);
        z-index: -1;
      }
    }
  }

  &__top {
    //background-color: var(--color-white);
  }
}