@use "../mixins/mq";

.teaser-animation {
    display: grid;
    gap: 6rem var(--grid-gap);
    position: relative;

    &--has-link {
        padding-bottom: 10rem;
    }

    &__aside {
        display: grid;
        gap: 6rem var(--grid-gap);
    }

    &__facts {
        --spacing-list: 6rem;
        --list-item-margin-top: 2rem;
    }

    &__middle {
        margin: 2rem auto;
        max-width: 33%;
    }

    &__logo {
        --rotations: 180deg;
        transform: rotateY(90deg);
        opacity: 0;
        filter: drop-shadow(0 0 .1rem var(--color-black));

        &--reveal {
            animation: teaser-animation-spin both, teaser-animation-fade-in both;
            animation-timing-function: cubic-bezier(0.06, 0.64, 0.26, 1.06), linear;
            animation-delay: 500ms, 700ms;
            animation-duration: 1.5s, 1.5s;
        }
    }

    &__header-link {
        position: absolute;
        bottom: 0;
        left: 0;

        .faded-out & {
            display: none;
        }

        @include mq.mq($from: medium) {
            position: static;
            bottom: unset;
            left: 0;
        }
    }

    &__fixed-body {
        --gradient-height: 50px;
        --gradient-top: -50px;
        --gradient-bottom: 100%;


        -webkit-mask-image: linear-gradient(180deg,
                transparent var(--gradient-top),
                rgba(0, 0, 0, 1) calc(var(--gradient-top) + var(--gradient-height)),
                rgba(0, 0, 0, 1) calc(var(--gradient-bottom) - var(--gradient-height)),
                transparent var(--gradient-bottom));

        mask-image: linear-gradient(180deg,
                transparent var(--gradient-top),
                rgba(0, 0, 0, 1) calc(var(--gradient-top) + var(--gradient-height)),
                rgba(0, 0, 0, 1) calc(var(--gradient-bottom) - var(--gradient-height)),
                transparent var(--gradient-bottom));

        // background: #f00;

    }

    @include mq.mq($from: medium) {
        grid-template-columns: 2fr 1fr;


        &__aside {
            position: sticky;
            top: var(--sticky-offset-top);
            margin-bottom: auto;
            grid-template-columns: 1fr 1fr;
        }


        &__middle {
            //max-width: 66%;
            max-width: 56%; // reduced
        }

        &__header-link {
            display: block;
        }

        &__footer-link {
            display: none;
        }
    }
}


@keyframes teaser-animation-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes teaser-animation-spin {
    from {
        transform: rotateY(var(--rotations));
    }

    to {
        transform: rotateY(0deg);
    }
}